import Loader from 'components/Loader';
import { usePageContext } from 'contexts/PageContext';
import { FormGroup, Input, Label } from 'reactstrap';

export const PageNameForm = ({ disabled }) => {
  const {
    tagName,
    setTagName,
    tagNameState,
    setTagNameState,
    checkingTagName,
    setCheckingTagName,
    tagNameInUse,
  } = usePageContext();

  return (
    <FormGroup className={`${tagNameState}`}>
      <Label for="name-input">Page Name *</Label>
      <Input
        id="name-input"
        name="name-input"
        type="text"
        placeholder="Enter page title"
        value={tagName}
        onBlur={() => setCheckingTagName(true)}
        onChange={e => {
          setTagNameState(e.target.value ? 'has-success' : 'has-danger');
          setTagName(e.target.value);
        }}
        disabled={disabled}
      />
      {checkingTagName && <Loader type="spinner" width={20} height={20} />}
      {tagNameState === 'has-danger' ? (
        tagNameInUse ? (
          <Label for="name-input" className="error">
            This page name is already being used.
          </Label>
        ) : (
          <Label for="name-input" className="error">
            This field is required.
          </Label>
        )
      ) : null}
    </FormGroup>
  );
};
