import { createActions } from 'reduxsauce';

export interface IPageState {
  data: IPageData;
  isLoading: boolean;
  error: string;
}
export interface IPageData {
  clientPages: {
    pages: IPage[];
  };
  allPages: IPageData;
  pageDetail: IPage;
  pageCreated: IPageCreated;
}
export interface IPageCreated {
  query_string: string;
  page_name: string;
  page_id: number;
  event_id: string;
}

export type CommonPage = {
  value: string;
  query_str: string;
  client_id: string;
  is_enabled: boolean;
  desc: string;
  page_cta: string;
  page_misc: {
    page_background: string | null;
    page_color_text: string | null;
  };
  impact_score_visibility: boolean;
  page_type: 'LIST' | 'COMPACT';
  recipient_ids: string[];
};

export type IPage = CommonPage & {
  id: number;
  page_category: string;
  start_datetime: string;
  updated_date: string;
  donation_amounts: number[];
  status: string;
  processing_redirect: string;
  de_page: string;
};

export type BodyCreateProps<T extends 'donate' | 'event'> = T extends 'donate'
  ? DonatePageProps
  : EventPageProps;

export type DonatePageProps = CommonPage & {
  donation_amounts: number[];
};
export type EventPageProps = CommonPage & {
  subdescription: string;
  location_name: string;
  start_datetime: string;
  end_datetime: string | null;
};

export type BodyUpdateProps<T extends 'donate' | 'event'> = T extends 'donate'
  ? DonateBodyUpdateProps
  : EventBodyUpdateProps;

export type DonateBodyUpdateProps = {
  client_id: string;
  page: CommonPage & DonatePageProps;
};

export type EventBodyUpdateProps = CommonPage &
  EventPageProps & {
    client_id: string;
  };

export type IFile = {
  name: string;
  size: number;
  type: string;
};

export type ITicket = {
  name: string;
  description?: string;
  price: number;
  quantity: number;
  quantity_remaining?: number;
  status: string;
  order: number;
  id: string;
};

export interface GetSignedUrlConfig {
  action: 'read' | 'write' | 'delete' | 'resumable';
  expires: number | string;
  version?: 'v2' | 'v4';
  cname?: string;
  contentMd5?: string;
  contentType?: string;
  promptSaveAs?: string;
  responseDisposition?: string;
  responseType?: string;
  virtualHostedStyle?: boolean;
}

export interface PagesTypes {
  PAGES: 'PAGES';
  PAGES_START: 'PAGES_START';
  ALL_PAGES_SUCCESS: 'ALL_PAGES_SUCCESS';
  PAGES_SUCCESS: 'PAGES_SUCCESS';
  PAGES_ERROR: 'PAGES_ERROR';
  PAGE_CREATED_SUCCESS: 'PAGE_CREATED_SUCCESS';
  PAGE_CREATED_ERROR: 'PAGE_CREATED_ERROR';
  PAGE_DETAIL_SUCCESS: 'PAGE_DETAIL_SUCCESS';
  PAGE_DETAIL_ERROR: 'PAGE_DETAIL_ERROR';
  PAGE_CLEAN_UP: 'PAGE_CLEAN_UP';
}

const { Types, Creators } = createActions<PagesTypes>({
  pages: ['data'],
  pagesStart: null,
  allPagesSuccess: ['data'],
  pagesSuccess: ['data'],
  pagesError: ['error'],
  pageCreatedSuccess: ['data'],
  pageCreatedError: ['error'],
  pageDetailSuccess: ['data'],
  pageDetailError: ['error'],
  pageCleanUp: null,
});

export { Types };

export default Creators;
