import React, { createContext, Dispatch, SetStateAction, useContext, useState } from 'react';
import { showToast } from 'components/Toast';
import { getUrlParams } from 'helpers/utils';
import { useAppDispatch } from 'helpers/hooks';
import { getClientID } from 'services/storage';
import { createPage, updatePage } from 'store/pages/actions';
import { usePageContext } from './PageContext';

export interface IRecipient {
  value: string;
  label: string;
}

interface DonationPageContextType {
  donationAmounts: number[];
  tempDonationAmount: number;
  donationTabStatus: string[];

  // Methods
  setDonationAmounts: (value: number[]) => void;
  setTempDonationAmount: (value: number) => void;
  addDonationAmount: () => void;
  removeItemAmount: (value: number) => void;
  handleSubmitDonation: () => void;
  saveDonationPageDraft: (tab: number) => boolean;
  setDonationTabStatus: Dispatch<SetStateAction<string[]>>;
}

const DonationPageContext = createContext<DonationPageContextType | undefined>(undefined);

export const DonationPageProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const dispatch = useAppDispatch();
  const initialTabStatus = ['', ''];
  const [tempDonationAmount, setTempDonationAmount] = useState<number>(0);
  const [donationAmounts, setDonationAmounts] = useState([10, 25, 50, 100, 250]);
  const [donationTabStatus, setDonationTabStatus] = useState(initialTabStatus);

  // Common page context variables
  const {
    tagName,
    tagNameState,
    tagDescription,
    tagDescriptionState,
    queryString,
    queryStringState,
    tagCTA,
    bgColor,
    textColor,
    impactScoreVisibility,
    pageTypeView,
    recipientsSelected,
    editPageSelected,
    isPageDuplicated,
    setTagNameState,
    setTagDescriptionState,
    setQueryStringState,
  } = usePageContext();

  // Methods
  const addDonationAmount = () => {
    if (!donationAmounts.includes(tempDonationAmount)) {
      let amounts = donationAmounts;
      if (tempDonationAmount) amounts = [...donationAmounts, tempDonationAmount];
      setDonationAmounts(amounts.sort((a, b) => a - b));
      setTempDonationAmount(0);
    }
  };

  const removeItemAmount = (amount: number) => {
    setDonationAmounts(prevAmounts => prevAmounts.filter(item => item !== amount));
  };

  const checksTab = [
    tagNameState === 'has-success' &&
      tagDescriptionState === 'has-success' &&
      queryStringState === 'has-success',
    donationAmounts.length > 0,
  ];

  const saveDonationPageDraft = (tab: number) => {
    //Currently only checks if every field in this tab is correctly filled
    if (tab === 0) {
      if (!tagNameState) setTagNameState('has-danger');
      if (!tagDescriptionState) setTagDescriptionState('has-danger');
      if (!queryStringState) setQueryStringState('has-danger');
    }

    return checksTab[tab];
  };

  const handleSubmitDonation = () => {
    if (checksTab.every(item => item)) {
      setDonationTabStatus(['success', 'success']);
      const body = {
        value: tagName,
        query_str: queryString.toLowerCase(),
        desc: tagDescription,
        donation_amounts: donationAmounts,
        client_id: getClientID() || '',
        page_cta: tagCTA,
        page_misc: {
          page_background: bgColor,
          page_color_text: textColor,
        },
        impact_score_visibility: impactScoreVisibility,
        is_enabled: true,
        page_type: pageTypeView,
        recipient_ids: recipientsSelected?.map(item => item.value) || [],
        page_category: 'donate',
      };
      if (editPageSelected && !isPageDuplicated) {
        const updateBody = {
          client_id: getClientID() || '',
          page: {
            ...body,
            id: parseInt(getUrlParams().tagId || ''),
          },
        };
        dispatch(updatePage(updateBody, 'donate'));
      } else dispatch(createPage(body, 'donate'));
    } else {
      showToast('Some required fields are missing', 'error');
      checksTab.forEach((validStates, index) => {
        if (!validStates) {
          setDonationTabStatus(prevStatus =>
            prevStatus.map((s: string, i: number) => (i === index ? 'error' : s))
          );
        }
      });
      saveDonationPageDraft(0);
    }
  };

  return (
    <DonationPageContext.Provider
      value={{
        donationAmounts,
        tempDonationAmount,
        donationTabStatus,
        setDonationAmounts,
        setTempDonationAmount,
        addDonationAmount,
        removeItemAmount,
        handleSubmitDonation,
        saveDonationPageDraft,
        setDonationTabStatus,
      }}
    >
      {children}
    </DonationPageContext.Provider>
  );
};

export const useDonationPage = () => {
  const context = useContext(DonationPageContext);
  if (!context) {
    throw new Error('useDonationPage must be used within a DonationPageProvider');
  }
  return context;
};
