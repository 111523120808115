import { FC, CSSProperties } from 'react';
import IcomoonReact from 'icomoon-react';
import IconNames from './iconNames';
import iconSet from '../../assets/fonts/selection.json';
import colors from 'constants/colors';

export interface IconProps {
  name: string;
  size?: number;
  color?: string;
  onClick?: () => void;
  disabled?: boolean;
  style?: CSSProperties;
  className?: string;
}

const Icon: FC<IconProps> = (props: IconProps) => {
  const { name, color = colors.inkBlue, size = 16, disabled, onClick, style, className } = props;

  return (
    <IcomoonReact
      iconSet={iconSet}
      color={disabled ? colors.gray : color}
      size={size}
      icon={name}
      onClick={onClick}
      style={style}
      className={className}
    />
  );
};

export default Icon;

export { IconNames };
