import moment from 'moment';
import { useState } from 'react';
import ReactDatetime from 'react-datetime';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { useEventPage } from 'contexts/EventPageContext';

export const TimePickersForm = () => {
  const [key, setKey] = useState(0);
  const { startTime, setStartTime, endTime, setEndTime, timeState, setTimeState } = useEventPage();

  const handleChangeDates = (type: string, item) => {
    const formattedTime = item.format('LT');
    if (type === 'start') {
      setStartTime(formattedTime);
      if (endTime)
        setTimeState(item.isBefore(moment(endTime, 'LT')) ? 'has-success' : 'has-danger');
    } else {
      setEndTime(formattedTime);
      setTimeState(item.isAfter(moment(startTime, 'LT')) ? 'has-success' : 'has-danger');
    }
  };

  const resetToDefaultEndTime = () => {
    setEndTime('');
    setTimeState('has-success');
    setKey(prev => prev + 1); //force re-render
  };

  return (
    <Row>
      <Col md="4" className="padding0">
        <FormGroup className={`${timeState}`}>
          <Label for="start-date-input">Start Time *</Label>
          <ReactDatetime
            inputProps={{
              className: 'form-control picker-input',
              placeholder: 'Date Picker Here',
              readOnly: true,
            }}
            onChange={e => handleChangeDates('start', e)}
            value={startTime}
            timeFormat
            closeOnSelect
            dateFormat={false}
          />
        </FormGroup>
      </Col>
      <Col md="2" />
      <Col md="4" className="padding0">
        <FormGroup className={`${timeState}`}>
          <Label for="end-date-input">End Time (optional)</Label>
          <ReactDatetime
            key={key}
            inputProps={{
              className: 'form-control picker-input',
              placeholder: '',
              readOnly: true,
              id: 'end-date-input',
            }}
            onChange={e => handleChangeDates('end', e)}
            value={endTime}
            closeOnSelect
            dateFormat={false}
            timeFormat={true}
          />
          {timeState === 'has-danger' ? (
            <Label for="end-date-input" className="error">
              The end time value must be higher than the start time
            </Label>
          ) : null}
          {endTime && (
            <div>
              <span className="oath-utility link-text gray" onClick={resetToDefaultEndTime}>
                Reset End Time
              </span>
            </div>
          )}
        </FormGroup>
      </Col>
    </Row>
  );
};
