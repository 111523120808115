import React, { createContext, Dispatch, SetStateAction, useContext, useState } from 'react';
import { usePageContext } from './PageContext';
import moment from 'moment';
import { showToast } from 'components/Toast';
import { getClientID } from 'services/storage';
import { getUrlParams } from 'helpers/utils';
import { useAppDispatch } from 'helpers/hooks';
import { createPage, updatePage } from 'store/pages/actions';
import { convertToFullDatetime } from 'helpers/date';
import { ITicket } from 'store/pages/types';

export interface IRecipient {
  value: string;
  label: string;
}

interface EventPageContextType {
  subcopy: string;
  subcopyState: string;
  startTime: string;
  endTime: string;
  eventDate: number;
  eventDateState: string;
  timeState: string;
  location: string;
  locationVisible: boolean;
  eventTabStatus: string[];
  rows: ITicket[];
  ticketArrengement: string;
  eventID: string | null;

  // Methods
  setSubcopy: (value: string) => void;
  setSubcopyState: (value: string) => void;
  setStartTime: (value: string) => void;
  setEventDate: (value: number) => void;
  setEventDateState: (value: string) => void;
  setTimeState: (value: string) => void;
  setEndTime: (value: string) => void;
  setLocation: (value: string) => void;
  setLocationVisible: (value: boolean) => void;
  handleSubmitEvent: () => void;
  saveEventPageDraft: (tab: number) => boolean;
  setEventTabStatus: Dispatch<SetStateAction<string[]>>;
  setRows: Dispatch<SetStateAction<ITicket[]>>;
  addRow: () => void;
  deleteRow: (ticketID: string) => void;
  setTicketArrengement: Dispatch<SetStateAction<string>>;
  setEventID: Dispatch<SetStateAction<string | null>>;
}

const EventPageContext = createContext<EventPageContextType | undefined>(undefined);

export const EventPageProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const dispatch = useAppDispatch();
  const initialTabStatus = ['', ''];
  const [subcopy, setSubcopy] = useState('');
  const [subcopyState, setSubcopyState] = useState('');
  const [startTime, setStartTime] = useState<string>(
    moment().startOf('hour').set('hour', 0).format('LT')
  );
  const [endTime, setEndTime] = useState<string>('');
  const [eventDate, setEventDate] = useState<number>(new Date().getTime());
  const [eventDateState, setEventDateState] = useState('');
  const [timeState, setTimeState] = useState('has-success');
  const [location, setLocation] = useState('');
  const [eventID, setEventID] = useState<string | null>(null);
  const [locationVisible, setLocationVisible] = useState(false);
  const [eventTabStatus, setEventTabStatus] = useState(initialTabStatus);
  const [rows, setRows] = useState<ITicket[]>([
    {
      id: '1',
      name: '',
      price: 0,
      quantity: 999,
      quantity_remaining: 999,
      status: 'ACTIVE',
      description: '',
      order: 0,
    },
  ]);
  const [ticketArrengement, setTicketArrengement] = useState('MANUALLY');

  // Common page context variables
  const {
    tagName,
    tagNameState,
    setTagNameState,
    tagDescription,
    tagDescriptionState,
    setTagDescriptionState,
    queryString,
    queryStringState,
    setQueryStringState,
    setTicketNameErrors,
    setTicketQuantityErrors,
    impactScoreVisibility,
    pageTypeView,
    bgColor,
    textColor,
    tagCTA,
    recipientsSelected,
    editPageSelected,
    isPageDuplicated,
  } = usePageContext();

  // Methods
  const locationSuccess = (location !== '' && locationVisible) || !locationVisible;

  const checksTab = [
    tagNameState === 'has-success' &&
      subcopyState === 'has-success' &&
      eventDateState === 'has-success' &&
      timeState === 'has-success' &&
      tagDescriptionState === 'has-success' &&
      queryStringState === 'has-success' &&
      locationSuccess,
    rows.every(row => row.name && row.quantity > 0),
  ];

  const saveEventPageDraft = (tab: number) => {
    if (tab === 0) {
      if (!tagNameState) setTagNameState('has-danger');
      if (!subcopyState) setSubcopyState('has-danger');
      if (!eventDateState) setEventDateState('has-danger');
      if (!timeState) setTimeState('has-danger');
      if (!tagDescriptionState) setTagDescriptionState('has-danger');
      if (!queryStringState) setQueryStringState('has-danger');
    }
    if (tab === 1) {
      if (rows.some(row => !row.name || row.quantity === 0)) {
        rows.forEach(row => {
          if (!row.name) setTicketNameErrors(prevErrors => [...prevErrors, row.id]);
          if (row.quantity === 0) setTicketQuantityErrors(prevErrors => [...prevErrors, row.id]);
        });
      }
    }

    return checksTab[tab];
  };

  const addRow = () => {
    setTicketNameErrors(prevErrors => [...prevErrors, (rows.length + 1).toString()]);
    setTicketQuantityErrors(prevErrors => [...prevErrors, (rows.length + 1).toString()]);
    setRows(prevRows => [
      ...prevRows,
      {
        id: (prevRows.length + 1).toString(),
        name: '',
        price: 0,
        quantity: 999,
        quantity_remaining: 999,
        status: 'ACTIVE',
        description: '',
        order: prevRows.length + 1,
      },
    ]);
  };

  const deleteRow = (ticketID: string) => {
    if (rows.length > 1) {
      setTicketNameErrors(prevErrors => prevErrors.filter(error => error !== ticketID));
      setTicketQuantityErrors(prevErrors => prevErrors.filter(error => error !== ticketID));
      setRows(prevRows =>
        prevRows.map(row => (row.id === ticketID ? { ...row, status: 'REMOVED' } : row))
      );
    }
  };

  const handleSubmitEvent = () => {
    if (checksTab.every(item => item)) {
      setEventTabStatus(['success', 'success']);
      const body = {
        value: tagName,
        subdescription: subcopy,
        start_datetime: convertToFullDatetime(
          `${moment(eventDate).format('YYYY-MM-DD')} ${startTime}`
        ),
        end_datetime: endTime
          ? convertToFullDatetime(`${moment(eventDate).format('YYYY-MM-DD')} ${endTime}`)
          : null,
        location_name: location,
        is_location_visible: locationVisible,
        page_misc: {
          page_background: bgColor,
          page_color_text: textColor,
        },
        desc: tagDescription,
        query_str: queryString.toLowerCase(),
        impact_score_visibility: impactScoreVisibility,
        page_type: pageTypeView,
        client_id: getClientID() || '',
        page_cta: tagCTA,
        is_enabled: true,
        recipient_ids: recipientsSelected?.map(item => item.value) || [],
        tickets: rows.map(({ id, ...row }, i) => ({
          ...row,
          order: ticketArrengement === 'MANUALLY' ? i : null,
          id: id?.length > 2 ? id : null,
        })),
        location_desc: '',
        address_1: '',
        address_2: '',
        event_id: eventID,
        city: '',
        state: '',
        zipcode: '',
        country: '',
        doors_open_datetime: convertToFullDatetime(
          `${moment(eventDate).format('YYYY-MM-DD')} ${startTime}`
        ),
        post_donate_msg: '',
        email_msg: '',
        page_category: 'event',
      };
      if (editPageSelected && !isPageDuplicated) {
        const updateBody = {
          ...editPageSelected[0],
          ...body,
          id: parseInt(getUrlParams().tagId || ''),
        };
        dispatch(updatePage(updateBody, 'event'));
      } else dispatch(createPage(body, 'event'));
    } else {
      showToast('Some required fields are missing', 'error');
      checksTab.forEach((validStates, index) => {
        if (!validStates) {
          setEventTabStatus(prevStatus =>
            prevStatus.map((s: string, i: number) => (i === index ? 'error' : s))
          );
        }
      });
      saveEventPageDraft(0);
      saveEventPageDraft(1);
    }
  };

  return (
    <EventPageContext.Provider
      value={{
        subcopy,
        subcopyState,
        startTime,
        endTime,
        eventDate,
        eventDateState,
        timeState,
        location,
        locationVisible,
        eventTabStatus,
        rows,
        ticketArrengement,
        eventID,
        setSubcopy,
        setSubcopyState,
        setStartTime,
        setEndTime,
        setEventDate,
        setEventDateState,
        setTimeState,
        setLocation,
        setLocationVisible,
        setEventTabStatus,
        saveEventPageDraft,
        handleSubmitEvent,
        setRows,
        addRow,
        deleteRow,
        setTicketArrengement,
        setEventID,
      }}
    >
      {children}
    </EventPageContext.Provider>
  );
};

export const useEventPage = () => {
  const context = useContext(EventPageContext);
  if (!context) {
    throw new Error('useEventPage must be used within a EventPageProvider');
  }
  return context;
};
