import ReactDatetime from 'react-datetime';
import { useEventPage } from 'contexts/EventPageContext';
import { FormGroup, Label } from 'reactstrap';
import { inputDate } from 'helpers/date';

export const DatePickerForm = () => {
  const { eventDate, setEventDate, eventDateState, setEventDateState } = useEventPage();

  const handleChangeDate = item => {
    setEventDate(new Date(item).getTime());
    setEventDateState(
      new Date(item).getTime() > new Date().getTime() ? 'has-success' : 'has-danger'
    );
  };

  return (
    <FormGroup className={`${eventDateState}`}>
      <Label for="event-date-input">Event Date *</Label>
      <ReactDatetime
        inputProps={{
          className: 'form-control picker-input',
          placeholder: 'Date Picker Here',
          readOnly: true,
        }}
        onChange={handleChangeDate}
        value={inputDate(eventDate)}
        closeOnSelect
        timeFormat={false}
      />
      {eventDateState === 'has-danger' ? (
        <Label for="end-date-input" className="error">
          The date of the event should be after today
        </Label>
      ) : null}
    </FormGroup>
  );
};
