import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { DragNDrop } from 'components/DragNDrop';
import { useEventPage } from 'contexts/EventPageContext';

export const DonationTicketForm = () => {
  const labels = [
    'Ticket Types *',
    'Pricing per Ticket *',
    'Quantity *',
    'Caption Preview (optional)',
  ];
  const { rows, setRows, addRow, deleteRow, ticketArrengement, setTicketArrengement } =
    useEventPage();

  return (
    <FormGroup>
      <Label for="donation-tickets">
        Add and edit your ticket options below. Minimum of 1 ticket type required for an event.
      </Label>
      <DragNDrop
        rows={rows.filter(row => row.status === 'ACTIVE')}
        setRows={setRows}
        labels={labels}
        deleteRow={deleteRow}
        deleteText="Delete Ticket"
      />
      <span className="oath-bodysmall link-text gray" onClick={addRow}>
        Add Ticket Type
      </span>
      <FormGroup tag="fieldset" className="margin0">
        <Label for="ticket-arrangement">Ticket Arrangement</Label>
        <Row>
          <Col md="2" className="padding0">
            <FormGroup check>
              <Input
                id="radio-arrangement"
                name="radio-arrangement"
                type="radio"
                checked={ticketArrengement === 'MANUALLY'}
                onChange={() => setTicketArrengement('MANUALLY')}
              />
              <Label for="radio-arrangement">Manually</Label>
            </FormGroup>
          </Col>
          <Col md="2" className="padding0">
            <FormGroup check>
              <Input
                id="radio-arrengement2"
                name="radio-arrengement2"
                type="radio"
                checked={ticketArrengement === 'BY_PRICE'}
                onChange={() => setTicketArrengement('BY_PRICE')}
              />
              <Label for="radio-arrengement2">By Ticket Price</Label>
            </FormGroup>
          </Col>
        </Row>
        {ticketArrengement === 'MANUALLY' && (
          <Label for="ticket-arrangement">
            Drag and drop ticket types in the order you would like them to appear on the screen,
            from top to bottom.
          </Label>
        )}
      </FormGroup>
    </FormGroup>
  );
};
