import { FC, useState } from 'react';
import { Row } from 'reactstrap';
import classNames from 'classnames';
import { BottomSheet } from 'react-spring-bottom-sheet';
import colors from 'constants/colors';
import Icon, { IconNames } from 'components/Icon';
import { tableDate } from 'helpers/date';
import { getInitials } from 'helpers/utils';
import { IDonorTotal } from 'store/donor/types';

interface ClickableProps {
  isMini: boolean;
  isOpen?: boolean;
  setIsMini: React.Dispatch<React.SetStateAction<boolean>>;
  handleBottomClick?: () => void;
}
interface Props {
  isMini: boolean;
  donors: IDonorTotal[];
  isBottom?: boolean;
}
interface BottomProps {
  isMini: boolean;
  setIsMini: React.Dispatch<React.SetStateAction<boolean>>;
  donors: IDonorTotal[];
}

export const RecentActivityClickable: FC<ClickableProps> = ({
  isMini,
  setIsMini,
  handleBottomClick,
  isOpen,
}) => {
  const handleClick = () => {
    if (handleBottomClick) {
      handleBottomClick();
    } else {
      setIsMini(prev => !prev);
    }
  };

  return (
    <div
      className={classNames(`recent-activity-clickable${handleBottomClick ? '-bottom' : ''}`, {
        'recent-activity-mini': isMini && !handleBottomClick,
      })}
      onClick={handleClick}
    >
      <Icon
        name={IconNames.ChevronLeft}
        className="chevron-left display-none"
        color={colors.inkBlue}
      />
      <span className="oath-h4 inkBlue">Recent Activity</span>
      <Icon
        name={
          handleBottomClick
            ? isOpen
              ? IconNames.ChevronDown
              : IconNames.ChevronUp
            : IconNames.ChevronRight
        }
        className="chevron-right"
        color={colors.inkBlue}
      />
    </div>
  );
};

export const RecentActivity: FC<Props> = ({ isMini, donors, isBottom }) => {
  return (
    <div
      className={classNames(`recent-activity-sidebar${isBottom ? '-bottom' : ''}`, {
        'recent-activity-hidden': isMini,
      })}
    >
      {donors?.length ? (
        donors.slice(0, 40).map((donor, index) => (
          <div className="recent-activity-item" key={index}>
            <div className="recent-activity-initials">
              <span className="oath-body">{getInitials(donor.name)}</span>
            </div>
            <div className="recent-activity-content">
              <Row className="space-between">
                <div className="oath-bodysmall inkBlue">{donor.name}</div>
                <div className="oath-bodysmall inkBlue">${donor.donation_amount}</div>
              </Row>
              <div className="oath-utility gray underline">{donor.page}</div>
              <div className="oath-utility gray">{tableDate(donor.time)}</div>
            </div>
          </div>
        ))
      ) : (
        <div className="recent-activity-item">
          <span className="oath-bodysmall inkBlue">No recent activity on this period.</span>
        </div>
      )}
    </div>
  );
};

export const RecentActivityBottom: FC<BottomProps> = ({ isMini, setIsMini, donors }) => {
  const [open, setOpen] = useState(false);
  const onDismiss = () => setOpen(false);

  return (
    <div>
      <div className="bottom-sheet-header">
        <RecentActivityClickable
          isMini={isMini}
          setIsMini={setIsMini}
          handleBottomClick={() => setOpen(true)}
          isOpen={open}
        />
      </div>
      <BottomSheet
        open={open}
        onDismiss={onDismiss}
        defaultSnap={({ snapPoints, lastSnap }) => lastSnap ?? Math.min(...snapPoints)}
        snapPoints={({ maxHeight }) => [maxHeight - maxHeight / 5, maxHeight * 0.2]}
        header={
          <RecentActivityClickable
            isMini={isMini}
            setIsMini={setIsMini}
            handleBottomClick={onDismiss}
            isOpen={open}
          />
        }
      >
        <div className="bottom-sheet-content">
          <RecentActivity isMini={isMini} donors={donors} isBottom />
        </div>
      </BottomSheet>
    </div>
  );
};
