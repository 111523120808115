import { usePageContext } from 'contexts/PageContext';
import { FormGroup, Input, Label } from 'reactstrap';

export const CTAForm = ({ title, label }) => {
  const { tagCTA, setTagCTA } = usePageContext();

  return (
    <>
      <span className="oath-eyebrow inkBlue">{title}</span>
      <FormGroup>
        <Label for="cta-input">{label}</Label>
        <Input
          id="cta-input"
          name="cta-input"
          type="text"
          value={tagCTA}
          placeholder="CTA copy"
          onChange={e => setTagCTA(e.target.value)}
        />
      </FormGroup>
    </>
  );
};
