import { ENV_CONFIG } from 'config/environment';
import { usePageContext } from 'contexts/PageContext';
import { useDonationPage } from 'contexts/DonationPageContext';
import { useAppDispatch } from 'helpers/hooks';
import { getRecipients } from 'store/recipients/actions';
import { useCommunityPage } from 'contexts/CommunityPageContext';
import { useEventPage } from 'contexts/EventPageContext';
import { formatDate } from 'helpers/date';

export const usePagesDetail = () => {
  const dispatch = useAppDispatch();

  const {
    editPageSelected,
    isPageDuplicated,
    setTagName,
    setTagNameState,
    tagName,
    checkingTagName,
    setCheckingTagName,
    setTagNameInUse,
    setTagDescription,
    setTagDescriptionState,
    setQueryString,
    setTagCTA,
    setBgColor,
    setTextColor,
    setImpactScoreVisibility,
    setPageTypeView,
    setPreview,
    setQueryStringState,
    setIsUpdatingRecipients,
    isUpdatingRecipients,
    setRecipientsSelected,
    setRecipients,
    checkingWebsite,
    setCheckingWebsite,
    setWebsiteInUse,
    queryString,
  } = usePageContext();

  const { setDonationAmounts } = useDonationPage();

  const {
    setSubcopy,
    setSubcopyState,
    setLocation,
    setLocationVisible,
    setEventDate,
    setEventDateState,
    setStartTime,
    setEndTime,
    setTimeState,
    setRows,
    setEventID,
  } = useEventPage();

  const {
    setSummaryPreview,
    setVideoPreview,
    setMiniFilesPreview,
    setCommunityQueryStringState,
    communityQueryString,
  } = useCommunityPage();

  const initializeEditPages = () => {
    if (editPageSelected) {
      setTagName(
        isPageDuplicated ? `${editPageSelected[0].value} Copy` : editPageSelected[0].value
      );
      setQueryString(
        isPageDuplicated ? `${editPageSelected[0].query_str}-copy` : editPageSelected[0].query_str
      );
      setTagDescription(editPageSelected[0].desc);
      setDonationAmounts(editPageSelected[0].donation_amounts);
      setTagCTA(editPageSelected[0].page_cta);
      setBgColor(editPageSelected[0].page_misc?.page_background);
      setTextColor(editPageSelected[0].page_misc?.page_color_text);
      setImpactScoreVisibility(editPageSelected[0].impact_score_visibility);
      setPageTypeView(editPageSelected[0].page_type);
      setPreview(
        `${ENV_CONFIG().CLOUD_STORAGE.URL}/${ENV_CONFIG().CLOUD_STORAGE.TAGS}/tag_${editPageSelected[0].id}.png`
      );
      setTagNameState('has-success');
      setQueryStringState('has-success');
      setTagDescriptionState('has-success');
      if (editPageSelected[0].recipient_ids) {
        setIsUpdatingRecipients(true);
        dispatch(getRecipients({ recipient_ids: editPageSelected[0].recipient_ids }));
      }

      if (editPageSelected[0].page_category === 'event') {
        setSubcopy(editPageSelected[0].subdescription);
        setLocation(editPageSelected[0].location_name);
        setLocationVisible(editPageSelected[0].is_location_visible);
        setEventDate(new Date(editPageSelected[0].start_datetime).getTime());
        setStartTime(formatDate(editPageSelected[0].start_datetime, 'h:mm A'));
        setEndTime(
          editPageSelected[0].end_datetime
            ? formatDate(editPageSelected[0].end_datetime, 'h:mm A')
            : ''
        );
        setRows(editPageSelected[0].tickets);
        setEventID(editPageSelected[0].event_id);
        setSubcopyState('has-success');
        setEventDateState('has-success');
        setTimeState('has-success');
      }
    }
  };

  const initializePreviewFile = (
    file: File | { [key: string]: File[] } | null | undefined,
    type: string
  ) => {
    const setPreviewFunction:
      | ((preview: string) => void)
      | ((preview: { [key: string]: string }) => void) =
      type === 'tag'
        ? setPreview
        : type === 'summary'
          ? setSummaryPreview
          : type === 'video'
            ? setVideoPreview
            : setMiniFilesPreview;

    if (!setPreviewFunction) return;

    if (!file) {
      if (type.includes('file')) {
        (setPreviewFunction as (preview: { [key: string]: string }) => void)({});
      } else {
        (setPreviewFunction as (preview: string) => void)('');
      }
      return;
    }

    let objectUrl = '';
    let arrayUrl: { [key: string]: string } = {};

    if (type.includes('file')) {
      if (typeof file === 'object' && !Array.isArray(file) && file !== null) {
        // file is an object with keys like 'file-1', 'file-2', etc.
        arrayUrl = Object.keys(file).reduce((acc: { [key: string]: string }, key) => {
          const filesArray = file[key];
          if (Array.isArray(filesArray)) {
            filesArray.forEach(f => {
              acc[key] = URL.createObjectURL(f);
            });
          }
          return acc;
        }, {});
      }
      (setPreviewFunction as (preview: string | { [key: string]: string }) => void)(arrayUrl);
    } else {
      if (file instanceof File) {
        objectUrl = URL.createObjectURL(file);
        (setPreviewFunction as (preview: string) => void)(objectUrl);
      }
    }

    return () => {
      Object.values(arrayUrl).forEach(url => URL.revokeObjectURL(url));
      if (objectUrl) {
        URL.revokeObjectURL(objectUrl);
      }
    };
  };

  const initializeRecipients = recipientsState => {
    if (!recipientsState.isLoading && recipientsState.data) {
      if (isUpdatingRecipients) {
        setIsUpdatingRecipients(false);
        setRecipientsSelected(
          recipientsState.data.recipients.map(rec => ({
            value: rec.recipient_id,
            label: rec.recipient_name,
          }))
        );
      } else setRecipients(recipientsState.data?.recipients);
    }
  };

  const checkNotRepeatedValues = pagesState => {
    if (!pagesState.isLoading && pagesState.allPages) {
      if (checkingWebsite) {
        setCheckingWebsite(false);

        const checkQueryString = (queryString: string, setState: (state: string) => void) => {
          const isInUse = pagesState.allPages?.pages?.some(
            item => item?.query_str?.toLowerCase() === queryString?.toLowerCase()
          );

          isInUse && setState('has-danger');
          setWebsiteInUse(isInUse);
        };

        checkQueryString(queryString, setQueryStringState);
        checkQueryString(communityQueryString, setCommunityQueryStringState);
      }
      if (checkingTagName) {
        if (editPageSelected && editPageSelected[0].value === tagName) {
          setTagNameInUse(false);
        } else {
          const isTagNameInUse = pagesState.allPages?.pages?.some(
            item => item?.value?.toLowerCase() === tagName?.toLowerCase()
          );

          if (isTagNameInUse) {
            setTagNameState('has-danger');
            setTagNameInUse(true);
          } else {
            setTagNameInUse(false);
          }
        }
        setCheckingTagName(false);
      }
    }
  };

  return {
    initializeEditPages,
    initializePreviewFile,
    initializeRecipients,
    checkNotRepeatedValues,
  };
};
