import { useState } from 'react';
import { Col, FormGroup, Input, Label } from 'reactstrap';
import { DragNDrop } from 'components/DragNDrop';
import { formatNumberAsCurrency } from 'helpers/utils';
import { useCommunityPage } from 'contexts/CommunityPageContext';

export const DonationEventCardsForm = () => {
  const [searchValue, setSearchValue] = useState('');
  const { rows, setRows, addRow, deleteRow, onErrorRow } = useCommunityPage();
  const labels = [
    'Drag & drop Donation / Event Page in the order you would like them to appear, top to bottom.',
    <>
      Set Page Fundraising. <br /> Goals Below
    </>,
    '',
    <>
      Optional Donation/Event Image. <br /> Recommended size: 400x400px
    </>,
    'Caption Preview (optional)',
  ];

  return (
    <FormGroup>
      <span className="oath-eyebrow inkBlue">Donation & Event Cards</span>
      <Col md="6" className="padding0">
        <Label for="community-input">
          Add Your Donation & Event Cards for the Community Page here
        </Label>
        <Input
          id="community-input"
          name="community-input"
          type="text"
          placeholder="Search"
          value={searchValue}
          onChange={e => setSearchValue(e.target.value)}
          onKeyDown={e => {
            if (e.key === 'Enter' && searchValue) {
              e.preventDefault();
              addRow(searchValue);
              setSearchValue('');
            }
          }}
        />
      </Col>
      {rows.length > 0 && (
        <>
          <DragNDrop
            rows={rows}
            setRows={setRows}
            labels={labels}
            deleteRow={deleteRow}
            deleteText="Delete Issue"
            onError={onErrorRow}
          />
          <table className="drag-n-drop-below-table">
            <thead>
              <tr>
                <th>
                  <Label>Total Amount of Featured Donations / Events:</Label>
                </th>
                <th>
                  <Label>Total Page Goal:</Label>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <Input type="text" value={rows.length} className="ticket-input" disabled />
                </td>
                <td>
                  <Input
                    type="text"
                    value={formatNumberAsCurrency(rows.reduce((sum, row) => sum + row.price, 0))}
                    className="ticket-input small"
                    disabled
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </>
      )}
    </FormGroup>
  );
};
