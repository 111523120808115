import React, { ReactElement, useState } from 'react';
import { Label, Input, Button } from 'reactstrap';
import classNames from 'classnames';
import colors from 'constants/colors';
import { ModalComponent } from 'components/Modal';
import Icon, { IconNames } from 'components/Icon';
import QuantityInput from 'components/QuantityInput';
import { usePageContext } from 'contexts/PageContext';
import { useCommunityPage } from 'contexts/CommunityPageContext';
import { formatNumberAsCurrency, limitChars, parseCurrency } from 'helpers/utils';
import { ITicket } from 'store/pages/types';

interface DragNDropProps<T> {
  rows: T[];
  setRows: React.Dispatch<React.SetStateAction<T[]>>;
  labels: (string | ReactElement)[];
  deleteRow: (ticketID: string) => void;
  deleteText: string;
  onError?: (key: string) => void;
}

export const DragNDrop = <T extends ITicket>({
  rows,
  setRows,
  labels,
  deleteRow,
  deleteText,
  onError,
}: DragNDropProps<T>) => {
  const {
    onSelectFile,
    ticketNameErrors,
    setTicketNameErrors,
    ticketQuantityErrors,
    setTicketQuantityErrors,
  } = usePageContext();
  const { miniFilesPreview } = useCommunityPage();
  const [draggingId, setDraggingId] = useState<string | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [captionText, setCaptionText] = useState('');
  const [rowSelected, setRowSelected] = useState<T>();
  const tableWithQuantity = Object.keys(rows[0]).includes('quantity');

  const handleDragStart = (id: string) => {
    setDraggingId(id);
  };

  const handleDragOver = (event: React.DragEvent, id: string) => {
    event.preventDefault();

    if (draggingId === id) return;

    const draggedIndex = rows.findIndex(row => row.id === draggingId);
    const targetIndex = rows.findIndex(row => row.id === id);
    const updatedRows = [...rows];
    const [draggedRow] = updatedRows.splice(draggedIndex, 1);
    updatedRows.splice(targetIndex, 0, draggedRow);

    setRows(updatedRows);
  };

  const handleDragEnd = () => {
    setDraggingId(null);
  };

  const handleInputChange = (id: string, field: keyof T, value: string | number) => {
    setRows(prevRows => prevRows.map(row => (row.id === id ? { ...row, [field]: value } : row)));
    if (field === 'name') {
      setTicketNameErrors(
        value ? ticketNameErrors.filter(item => item !== id) : [...ticketNameErrors, id]
      );
    } else if (field === 'quantity') {
      setTicketQuantityErrors(
        (value as number) > 0
          ? ticketQuantityErrors.filter(item => item !== id)
          : [...ticketQuantityErrors, id]
      );
    }
  };

  const showCaptionPopup = (row: T) => {
    setRowSelected(row);
    setCaptionText(row?.description || '');
    setIsOpen(true);
  };

  const closePopup = () => setIsOpen(false);

  const saveCaption = () => {
    handleInputChange(rowSelected?.id || '0', 'description', captionText);
    setCaptionText('');
    closePopup();
  };

  return (
    <>
      <ModalComponent isOpen={isOpen} closeModal={closePopup}>
        <span className="oath-eyebrow inkBlue">Add Ticket Caption</span>
        <span className="oath-body inkBlue">40 characters recommended.</span>
        <span className="oath-body inkBlue">100 characters max.</span>
        <Input
          type="text"
          value={captionText}
          className="input-popup"
          placeholder="Optional caption goes here"
          onChange={e => setCaptionText(e.target.value)}
        />
        <Button className="btn-inkBlue" onClick={saveCaption}>
          Save caption
        </Button>
        <span className="oath-bodysmall link-text inkBlue" onClick={closePopup}>
          Cancel
        </span>
      </ModalComponent>
      <table className="draggable-table">
        <thead>
          <tr>
            {labels.map((label, index) => (
              <th key={index} className={classNames({ 'quantity-th': !tableWithQuantity })}>
                <Label>{label}</Label>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map(row => (
            <tr
              key={row.id}
              draggable
              onDragStart={() => handleDragStart(row.id)}
              onDragOver={event => handleDragOver(event, row.id)}
              onDragEnd={handleDragEnd}
              className={classNames('row-draggable', draggingId === row.id ? 'dragging' : '')}
            >
              <td className={ticketNameErrors.includes(row.id) ? 'has-danger' : 'has-success'}>
                <Input
                  type="text"
                  value={row.name}
                  className="ticket-input"
                  onChange={e => handleInputChange(row.id, 'name', e.target.value)}
                />
              </td>
              <td>
                <Input
                  type="text"
                  value={formatNumberAsCurrency(row.price)}
                  min={0}
                  className="ticket-input small"
                  onFocus={e => e.target.select()}
                  onChange={e => handleInputChange(row.id, 'price', parseCurrency(e.target.value))}
                  onBlur={e => handleInputChange(row.id, 'price', parseCurrency(e.target.value))}
                />
              </td>
              {tableWithQuantity && (
                <td
                  className={classNames(
                    ticketQuantityErrors.includes(row.id) ? 'has-danger' : 'has-success'
                  )}
                >
                  <QuantityInput
                    value={
                      row.id?.length > 2
                        ? `${row.quantity} / ${row.quantity_remaining}`
                        : row.quantity
                    }
                    onChange={newQuantity => handleInputChange(row.id, 'quantity', newQuantity)}
                    min={0}
                    max={999}
                    disabled={row.id?.length > 2}
                  />
                </td>
              )}
              <td>
                <div className="row align-center justify-between">
                  <span>{limitChars(row.description, 40)}</span>
                  <div className="text-right">
                    <span
                      className="oath-bodysmall link-text gray"
                      onClick={() => showCaptionPopup(row)}
                    >
                      {row.description ? 'Edit Caption' : 'Add Caption'}
                    </span>
                  </div>
                </div>
              </td>
              <td className="icon-td">
                <Icon name={IconNames.Menu} color={colors.gray} size={16} />
              </td>
              {!tableWithQuantity && onError && (
                <td className="quantity-th">
                  <td>
                    <div className="upload-container-with-preview">
                      <img
                        id={`file-${row.id}`}
                        className={classNames('preview-small', {
                          'display-none': !miniFilesPreview[`file-${row.id}`],
                        })}
                        src={miniFilesPreview[`file-${row.id}`]}
                        onError={() => onError(`file-${row.id}`)}
                      />
                      <Input
                        id={`file-${row.id}`}
                        name={`file-${row.id}`}
                        type="file"
                        onChange={e => onSelectFile(e, `file-${row.id}`)}
                        className="invisible-input"
                      />
                      <Label for={`file-${row.id}`} className="custom-input-file">
                        Upload Image
                      </Label>
                    </div>
                  </td>
                </td>
              )}
              <td>
                <span
                  key={row.id}
                  className={classNames('oath-bodysmall link-text gray', {
                    disabled: rows.length === 1 && tableWithQuantity,
                  })}
                  onClick={() => deleteRow(row.id)}
                >
                  {deleteText}
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};
