import { useEventPage } from 'contexts/EventPageContext';
import { FormGroup, Input, Label } from 'reactstrap';

export const SubcopyForm = () => {
  const { subcopy, setSubcopy, subcopyState, setSubcopyState } = useEventPage();

  return (
    <FormGroup className={`${subcopyState}`}>
      <Label for="subcopy-input">Subcopy *</Label>
      <Input
        id="subcopy-input"
        name="subcopy-input"
        type="text"
        value={subcopy}
        onChange={e => {
          setSubcopyState(e.target.value ? 'has-success' : 'has-danger');
          setSubcopy(e.target.value);
        }}
      />
      {subcopyState === 'has-danger' ? (
        <Label for="subcopy-input" className="error">
          This field is required.
        </Label>
      ) : null}
    </FormGroup>
  );
};
