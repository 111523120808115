const IconNames: { [Keys: string]: string } = {
  Arrows: 'arrows',
  Bold: 'bold',
  Check: 'check',
  ChevronDown: 'chevron-down',
  ChevronLeft: 'chevron-left',
  ChevronRight: 'chevron-right',
  ChevronUp: 'chevron-up',
  Cog: 'cog',
  Copy: 'copy',
  Dashboard: 'dashboard',
  Edit: 'edit',
  Embed: 'embed',
  Eye: 'eye',
  Folder: 'folder',
  Italic: 'italic',
  List: 'list',
  ListNumbered: 'list-numbered',
  ListBulleted: 'list-bulleted',
  Menu: 'menu',
  MoneyBill: 'money-bill',
  ParagraphLeft: 'paragraph-left',
  ParagraphRight: 'paragraph-right',
  ParagraphCenter: 'paragraph-center',
  Search: 'search',
  Trash: 'trash',
  Underline: 'underline',
  User: 'user',
  XClose: 'x-close',
};

export default IconNames;
