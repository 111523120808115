import colors from 'constants/colors';
import { usePageContext } from 'contexts/PageContext';
import { FormGroup, Label, Row } from 'reactstrap';

export const ColorPickerForm = () => {
  const { bgColor, setBgColor, textColor, setTextColor } = usePageContext();

  const resetToDefaultColors = () => {
    setBgColor(null);
    setTextColor(null);
  };

  return (
    <Row className="gap4">
      <FormGroup className="grid-column">
        <Label for="bgcolor-input">Background Color</Label>
        <input
          id="bgcolor-input"
          name="bgcolor-input"
          type="color"
          className="input-color"
          value={bgColor || colors.inkBlue}
          onChange={e => setBgColor(e.target.value)}
        />
        <span className="oath-utility link-text gray" onClick={resetToDefaultColors}>
          Reset Colors
        </span>
      </FormGroup>
      <FormGroup className="grid-column content-start">
        <Label for="textcolor-input">Text Color</Label>
        <input
          id="textcolor-input"
          name="textcolor-input"
          type="color"
          className="input-color"
          value={textColor || colors.white}
          onChange={e => setTextColor(e.target.value)}
        />
      </FormGroup>
    </Row>
  );
};
