import { Button, Row } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import colors from 'constants/colors';
import { usePageContext } from 'contexts/PageContext';
import { useEventPage } from 'contexts/EventPageContext';
import { useDonationPage } from 'contexts/DonationPageContext';
import Icon, { IconNames } from 'components/Icon';
import { useCommunityPage } from 'contexts/CommunityPageContext';

export const PageDetailHeader = ({ pageType }) => {
  const navigate = useNavigate();
  const { handleSubmitDonation } = useDonationPage();
  const { handleSubmitEvent } = useEventPage();
  const { handleSubmitCommunity } = useCommunityPage();
  const { editPageSelected, isPageDuplicated } = usePageContext();

  const saveForm = () => {
    if (pageType === 'donate') handleSubmitDonation();
    if (pageType === 'event') handleSubmitEvent();
    // if (pageType === 'community') handleSubmitCommunity();
  };

  return (
    <Row className="filters-row with-padding">
      <div className="grid-column">
        <span className="oath-h3 inkBlue">
          {editPageSelected && !isPageDuplicated ? 'Edit your Existing Page' : 'Create a New Page'}
        </span>
        <Row className="gap4">
          <Icon name={IconNames.ChevronLeft} color={colors.white} className="chevron-svg-inkblue" />
          <span className="oath-bodysmall inkBlue link-text" onClick={() => navigate(-1)}>
            Back to All Pages
          </span>
        </Row>
      </div>
      <Row>
        {/* <Button
          className="btn-inkBlue-inverted small-button"
          onClick={() => console.log('Do nothing')}
        >
          Preview
        </Button> */}
        <Button className="btn-inkBlue small-button" onClick={saveForm}>
          Publish
        </Button>
      </Row>
    </Row>
  );
};
