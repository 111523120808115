export const INITIAL_STATE = {
  isLoading: false,
  error: null,
  data: {
    clientPages: null,
    allPages: null,
    pageDetail: null,
    pageCreated: null,
  },
};
