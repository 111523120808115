import dayjs from 'dayjs';

export const MS_PER_SECOND = 1000;
export const SECONDS_PER_MINUTE = 60;
export const MINUTES_PER_HOUR = 60;
export const HOURS_PER_DAY = 24;

//IN: Date
//OUT: format... 'DD/MM/YYYY' | 'HH:mm' | 'YYYY-MM-DD HH:MM:ss'
export const formatDate = (date, format) => dayjs(date).format(format);

export const chartDate = (date: number) => formatDate(date, 'MM-DD');

export const inputDate = (date: number) => formatDate(date, 'MM/DD/YYYY');

export const tableDate = (date: string | undefined) => formatDate(date, 'MM/DD/YYYY hh:mma');

const get_date_start = (date: number) => formatDate(date, 'YYYY-MM-DD 00:00:00');

const get_date_end = (date: number) => formatDate(date, 'YYYY-MM-DD 23:59:59');

export const convertToFullDatetime = (datetime: string): string => {
  return dayjs(datetime, 'YYYY-MM-DD h:mm A')
    .set('second', 0)
    .set('millisecond', 0)
    .format('YYYY-MM-DDTHH:mm:ss.SSSSSS');
};

export const urlDate = (date, type) => {
  let dateFormatted = formatDate(date, 'YYYY-MM-DD HH:mm:ss');
  if (type === 'start') dateFormatted = get_date_start(date);
  if (type === 'end') dateFormatted = get_date_end(date);

  const newDate = dateFormatted.replace(' ', '%20').replace(/[:]/g, '%3A');

  return newDate;
};
