import classNames from 'classnames';
import { useEventPage } from 'contexts/EventPageContext';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';

export const LocationForm = () => {
  const { location, setLocation, locationVisible, setLocationVisible } = useEventPage();

  return (
    <Row>
      <Col md="8" className="padding0">
        <FormGroup className={classNames({ 'has-danger': location === '' && locationVisible })}>
          <Label for="location-input">Location (optional)</Label>
          <Input
            id="location-input"
            name="location-input"
            type="text"
            value={location}
            disabled={!locationVisible}
            onChange={e => setLocation(e.target.value)}
          />
          {location === '' && locationVisible ? (
            <Label for="name-input" className="error">
              This field is required.
            </Label>
          ) : null}
        </FormGroup>
      </Col>
      <Col md="4">
        <Label for="radio-location">Show location on event page?</Label>
        <FormGroup check>
          <Input
            id="radio-location"
            name="radio-location"
            type="radio"
            checked={locationVisible}
            onChange={() => setLocationVisible(true)}
          />
          <Label for="radio-location">YES</Label>
        </FormGroup>
        <FormGroup check>
          <Input
            id="radio-location2"
            name="radio-location2"
            type="radio"
            checked={!locationVisible}
            onChange={() => setLocationVisible(false)}
          />
          <Label for="radio-location2">No, hide the location</Label>
        </FormGroup>
      </Col>
    </Row>
  );
};
