import { FC } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import Loader from 'components/Loader';
import { ENV_CONFIG } from 'config/environment';
import { usePageContext } from 'contexts/PageContext';
import { useCommunityPage } from 'contexts/CommunityPageContext';
import { transformToUrl } from 'helpers/utils';
import { websiteWithLines } from 'helpers/regexp';

interface Props {
  type: 'community' | 'tag';
  pageType: string;
}
export const WebsiteURLForm: FC<Props> = ({ type, pageType }) => {
  const {
    editPageSelected,
    isPageDuplicated,
    queryString,
    setQueryString,
    queryStringState,
    setQueryStringState,
    websiteInUse,
    checkingWebsite,
    setCheckingWebsite,
  } = usePageContext();

  const {
    communityQueryStringState,
    setCommunityQueryStringState,
    communityQueryString,
    setCommunityQueryString,
  } = useCommunityPage();

  const currentQueryString = type === 'community' ? communityQueryString : queryString;
  const setCurrentQueryString = type === 'community' ? setCommunityQueryString : setQueryString;
  const currentQueryStringState =
    type === 'community' ? communityQueryStringState : queryStringState;
  const setCurrentQueryStringState =
    type === 'community' ? setCommunityQueryStringState : setQueryStringState;

  return (
    <FormGroup className={currentQueryStringState}>
      <span className="oath-eyebrow inkBlue">Website URL</span>
      <Input
        id={`querystring-input-${type}`}
        name={`querystring-input-${type}`}
        type="text"
        placeholder="example.com"
        value={currentQueryString}
        className="form-category"
        onBlur={() => setCheckingWebsite(true)}
        onChange={e => {
          setCurrentQueryStringState(
            e.target.value && websiteWithLines.test(e.target.value) && !e.target.value.endsWith('-')
              ? 'has-success'
              : 'has-danger'
          );
          setCurrentQueryString(transformToUrl(e.target.value));
        }}
        disabled={!!editPageSelected && !isPageDuplicated}
      />
      {currentQueryStringState === 'has-danger' ? (
        !websiteWithLines.test(currentQueryString) || currentQueryString.endsWith('-') ? (
          <Label for="querystring-input" className="error">
            This field has incorrect characters.
          </Label>
        ) : websiteInUse ? (
          <Label for="querystring-input" className="error">
            This URL is already being used.
          </Label>
        ) : (
          <Label for="querystring-input" className="error">
            This field is required.
          </Label>
        )
      ) : null}
      <div className="category form-category">
        {checkingWebsite && <Loader type="spinner" width={20} height={20} />}
        {pageType === 'event'
          ? `${ENV_CONFIG().EVENT_PAGE}${currentQueryString}`
          : `${ENV_CONFIG().PARTNER_PAGE}${currentQueryString}`}
      </div>
    </FormGroup>
  );
};
