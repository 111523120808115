import { SlateInput } from 'components/SlateInput';
import { usePageContext } from 'contexts/PageContext';
import { FormGroup, Label } from 'reactstrap';

export const DescriptionForm = ({
  value,
  setValue,
  valueState,
  setValueState,
  label,
  labelFor,
}) => {
  const { handleChangeDescription } = usePageContext();

  return (
    <FormGroup>
      <Label for={labelFor}>{label}</Label>
      <SlateInput
        id={labelFor}
        value={value}
        onChange={nodes => handleChangeDescription(nodes, setValue, setValueState)}
        valueState={valueState}
      />
      {valueState === 'has-danger' ? (
        <Label for={labelFor} className="errorRed">
          This field is required.
        </Label>
      ) : null}
    </FormGroup>
  );
};
