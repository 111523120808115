import { FC } from 'react';
import Modal from 'react-modal';
import colors from 'constants/colors';
import Icon, { IconNames } from 'components/Icon';

interface Props {
  isOpen: boolean;
  closeModal?: () => void;
  children: React.ReactNode;
}

export const ModalComponent: FC<Props> = ({ isOpen, closeModal, children }) => {
  return (
    <Modal
      appElement={document.getElementById('root') as HTMLElement}
      isOpen={isOpen}
      onRequestClose={closeModal}
      className="modal-component-content"
      overlayClassName="modal-component-overlay"
    >
      {children}
      {closeModal && (
        <Icon
          onClick={closeModal}
          name={IconNames.XClose}
          size={16}
          color={colors.inkBlue}
          className="close-button"
        />
      )}
    </Modal>
  );
};
