import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useState,
} from 'react';
import { SlateNode } from 'components/SlateInput/types';
import { convertToHTML } from 'components/SlateInput/utils';
import { IPage } from 'store/pages/types';
import { EventPageProvider } from './EventPageContext';
import { DonationPageProvider } from './DonationPageContext';
import { CommunityPageProvider } from './CommunityPageContext';
import { IRecipients } from 'store/recipients/types';

interface IRecipientSelected {
  value: string;
  label?: string;
}

interface PageContextType {
  tagName: string;
  tagNameState: string;
  tagNameInUse: boolean;
  checkingTagName: boolean;
  tagDescription: string;
  tagDescriptionState: string;
  itemVisible: number;
  editPageSelected: IPage | undefined;
  isPageDuplicated: boolean;
  queryString: string;
  queryStringState: string;
  checkingWebsite: boolean;
  websiteInUse: boolean;
  recipients: IRecipients[];
  recipientsSelected: IRecipientSelected[];
  bgColor: string | null;
  textColor: string | null;
  impactScoreVisibility: boolean;
  pageTypeView: 'LIST' | 'COMPACT';
  tagCTA: string;
  preview: string;
  selectedFile: File | null | undefined;
  selectedSummaryFile: File | null | undefined;
  selectedMiniFiles: { [key: string]: File[] };
  selectedVideoFile: File | null | undefined;
  isUpdatingRecipients: boolean;
  ticketNameErrors: string[];
  ticketQuantityErrors: string[];

  // Methods
  setTagName: (value: string) => void;
  setTagNameState: (value: string) => void;
  setCheckingTagName: (value: boolean) => void;
  setTagNameInUse: Dispatch<SetStateAction<boolean>>;
  setTagDescription: (value: string) => void;
  setTagDescriptionState: (value: string) => void;
  setItemVisible: (e: any) => void;
  setEditPageSelected: (value: IPage | undefined) => void;
  setIsPageDuplicated: (value: boolean) => void;
  handleChangeDescription: (
    nodes: SlateNode[],
    setValue: (val: string) => void,
    setValueState: (val: string) => void
  ) => void;
  setQueryString: (value: string) => void;
  setQueryStringState: (value: string) => void;
  setCheckingWebsite: (value: boolean) => void;
  setRecipientsSelected: (value: IRecipientSelected[]) => void;
  setBgColor: (value: string | null) => void;
  setTextColor: (value: string | null) => void;
  setImpactScoreVisibility: (value: boolean) => void;
  setPageTypeView: (value: 'LIST' | 'COMPACT') => void;
  setTagCTA: (value: string) => void;
  setPreview: (value: string) => void;
  removeItemRecipient: (recipient: IRecipientSelected) => void;
  setSelectedFile: (value: File | null) => void;
  setSelectedSummaryFile: (value: File | null) => void;
  setSelectedVideoFile: (value: File | null) => void;
  setSelectedMiniFiles: React.Dispatch<React.SetStateAction<{ [key: string]: File[] }>>;
  onSelectFile: (e: any, type: string) => void;
  setWebsiteInUse: (value: boolean) => void;
  setRecipients: (value: IRecipients[]) => void;
  setIsUpdatingRecipients: Dispatch<SetStateAction<boolean>>;
  setTicketNameErrors: Dispatch<SetStateAction<string[]>>;
  setTicketQuantityErrors: Dispatch<SetStateAction<string[]>>;
}

const PageContext = createContext<PageContextType | undefined>(undefined);

export const PageProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [tagName, setTagName] = useState('');
  const [tagNameState, setTagNameState] = useState('');
  const [tagNameInUse, setTagNameInUse] = useState(false);
  const [checkingTagName, setCheckingTagName] = useState(false);
  const [tagDescription, setTagDescription] = useState('');
  const [tagDescriptionState, setTagDescriptionState] = useState('');
  const [itemVisible, setItemVisible] = useState<number>(0);
  const [isPageDuplicated, setIsPageDuplicated] = useState(false);
  const [editPageSelected, setEditPageSelected] = useState<IPage>();
  const [tagCTA, setTagCTA] = useState('');
  const [preview, setPreview] = useState('');
  const [queryString, setQueryString] = useState('');
  const [websiteInUse, setWebsiteInUse] = useState(false);
  const [bgColor, setBgColor] = useState<string | null>(null);
  const [queryStringState, setQueryStringState] = useState('');
  const [checkingWebsite, setCheckingWebsite] = useState(false);
  const [recipients, setRecipients] = useState<IRecipients[]>([]);
  const [textColor, setTextColor] = useState<string | null>(null);
  const [isUpdatingRecipients, setIsUpdatingRecipients] = useState(false);
  const [impactScoreVisibility, setImpactScoreVisibility] = useState(true);
  const [selectedFile, setSelectedFile] = useState<File | null | undefined>();
  const [selectedSummaryFile, setSelectedSummaryFile] = useState<File | null | undefined>();
  const [selectedVideoFile, setSelectedVideoFile] = useState<File | null | undefined>();
  const [selectedMiniFiles, setSelectedMiniFiles] = useState<{ [key: string]: File[] }>({});
  const [pageTypeView, setPageTypeView] = useState<'LIST' | 'COMPACT'>('LIST');
  const [recipientsSelected, setRecipientsSelected] = useState<IRecipientSelected[]>([]);
  const [ticketNameErrors, setTicketNameErrors] = useState<string[]>(['1']);
  const [ticketQuantityErrors, setTicketQuantityErrors] = useState<string[]>([]);

  // Methods
  const handleChangeDescription = useCallback(
    (nodes: SlateNode[], setValue: (val: string) => void, setValueState: (val: string) => void) => {
      setValueState(nodes ? 'has-success' : 'has-danger');
      setValue(convertToHTML(nodes));
    },
    []
  );

  const removeItemRecipient = useCallback((recipient: IRecipientSelected) => {
    setRecipientsSelected(prev => prev.filter(r => r.value !== recipient.value));
  }, []);

  const onSelectFile = useCallback((e, type) => {
    if (!e.target.files || e.target.files.length === 0) {
      if (type === 'tag') setSelectedFile(undefined);
      if (type === 'summary') setSelectedSummaryFile(undefined);
      if (type === 'video') setSelectedVideoFile(undefined);
      if (type.includes('file')) setSelectedMiniFiles(prev => ({ ...prev, [type]: [] }));
      return;
    }
    const file = e.target.files[0];
    if (type === 'tag') setSelectedFile(file);
    if (type === 'summary') setSelectedSummaryFile(file);
    if (type === 'video') setSelectedVideoFile(file);
    if (type.includes('file')) {
      setSelectedMiniFiles(prev => ({
        ...prev,
        [type]: prev[type] ? [...prev[type], file] : [file],
      }));
    }
  }, []);

  return (
    <PageContext.Provider
      value={{
        tagName,
        tagNameState,
        checkingTagName,
        tagNameInUse,
        tagDescription,
        tagDescriptionState,
        itemVisible,
        editPageSelected,
        isPageDuplicated,
        queryString,
        queryStringState,
        checkingWebsite,
        websiteInUse,
        recipients,
        recipientsSelected,
        bgColor,
        textColor,
        impactScoreVisibility,
        pageTypeView,
        tagCTA,
        preview,
        selectedFile,
        selectedSummaryFile,
        selectedMiniFiles,
        selectedVideoFile,
        isUpdatingRecipients,
        ticketNameErrors,
        ticketQuantityErrors,
        setTagName,
        setTagNameState,
        setCheckingTagName,
        setTagNameInUse,
        setTagDescription,
        setTagDescriptionState,
        setItemVisible,
        setEditPageSelected,
        setIsPageDuplicated,
        handleChangeDescription,
        setQueryString,
        setQueryStringState,
        setCheckingWebsite,
        setRecipientsSelected,
        setBgColor,
        setTextColor,
        setImpactScoreVisibility,
        setPageTypeView,
        setTagCTA,
        setPreview,
        removeItemRecipient,
        setSelectedFile,
        setSelectedSummaryFile,
        setSelectedMiniFiles,
        setSelectedVideoFile,
        onSelectFile,
        setWebsiteInUse,
        setRecipients,
        setIsUpdatingRecipients,
        setTicketNameErrors,
        setTicketQuantityErrors,
      }}
    >
      <DonationPageProvider>
        <EventPageProvider>
          <CommunityPageProvider>{children}</CommunityPageProvider>
        </EventPageProvider>
      </DonationPageProvider>
    </PageContext.Provider>
  );
};

export const usePageContext = () => {
  const context = useContext(PageContext);
  if (!context) {
    throw new Error('usePage must be used within a PageProvider');
  }
  return context;
};
