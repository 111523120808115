import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { usePageContext } from './PageContext';
import { showToast } from 'components/Toast';
import { useDonationPage } from './DonationPageContext';
import { getUrlParams } from 'helpers/utils';
import { ITicket } from 'store/pages/types';

export interface IRecipient {
  value: string;
  label: string;
}

interface CommunityPageContextType {
  summaryPageName: string;
  summaryPageNameState: string;
  summaryDescription: string;
  summaryDescriptionState: string;
  m1Title: string;
  m1TitleState: string;
  m1CTA: string;
  m1CTAState: string;
  m2Title: string;
  m2TitleState: string;
  m2CTA: string;
  m2CTAState: string;
  m1Description: string;
  m1DescriptionState: string;
  m2Description: string;
  m2DescriptionState: string;
  websiteUrl: string;
  summaryPreview: string;
  selectedSummaryFile: File | null | undefined;
  videoPreview: string;
  miniFilesPreview: { [key: string]: string };
  rows: ITicket[];
  communityTabStatus: string[];
  communityQueryString: string;
  communityQueryStringState: string;

  // Methods
  setSummaryPageName: (value: string) => void;
  setSummaryPageNameState: (value: string) => void;
  setSummaryDescription: (value: string) => void;
  setSummaryDescriptionState: (value: string) => void;
  setM1Title: (value: string) => void;
  setM1TitleState: (value: string) => void;
  setM1CTA: (value: string) => void;
  setM1CTAState: (value: string) => void;
  setM2Title: (value: string) => void;
  setM2TitleState: (value: string) => void;
  setM2CTA: (value: string) => void;
  setM2CTAState: (value: string) => void;
  setM1Description: (value: string) => void;
  setM1DescriptionState: (value: string) => void;
  setM2Description: (value: string) => void;
  setM2DescriptionState: (value: string) => void;
  setWebsiteUrl: (value: string) => void;
  setSummaryPreview: (value: string) => void;
  setVideoPreview: (value: string) => void;
  setSelectedSummaryFile: (value: File | null) => void;
  setMiniFilesPreview: (value: { [key: string]: string }) => void;
  setRows: Dispatch<SetStateAction<ITicket[]>>;
  handleSubmitCommunity: () => void;
  setCommunityTabStatus: Dispatch<SetStateAction<string[]>>;
  saveCommunityPageDraft: (tab: number) => boolean;
  addRow: (text: string) => void;
  deleteRow: (ticketID: string) => void;
  onErrorRow: (key: string) => void;
  setCommunityQueryString: (value: string) => void;
  setCommunityQueryStringState: (value: string) => void;
}

const CommunityPageContext = createContext<CommunityPageContextType | undefined>(undefined);

export const CommunityPageProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const initialTabStatus = ['', '', '', ''];
  const [summaryPageName, setSummaryPageName] = useState('');
  const [summaryPageNameState, setSummaryPageNameState] = useState('');
  const [summaryDescription, setSummaryDescription] = useState('');
  const [summaryDescriptionState, setSummaryDescriptionState] = useState('');
  const [m1Title, setM1Title] = useState('');
  const [m1TitleState, setM1TitleState] = useState('');
  const [m1CTA, setM1CTA] = useState('');
  const [m1CTAState, setM1CTAState] = useState('');
  const [m2Title, setM2Title] = useState('');
  const [m2TitleState, setM2TitleState] = useState('');
  const [m2CTA, setM2CTA] = useState('');
  const [m2CTAState, setM2CTAState] = useState('');
  const [m1Description, setM1Description] = useState('');
  const [m1DescriptionState, setM1DescriptionState] = useState('');
  const [m2Description, setM2Description] = useState('');
  const [m2DescriptionState, setM2DescriptionState] = useState('');
  const [websiteUrl, setWebsiteUrl] = useState('');
  const [summaryPreview, setSummaryPreview] = useState('');
  const [selectedSummaryFile, setSelectedSummaryFile] = useState<File | null | undefined>();
  const [videoPreview, setVideoPreview] = useState('');
  const [miniFilesPreview, setMiniFilesPreview] = useState({});
  const [rows, setRows] = useState<ITicket[]>([]);
  const [communityTabStatus, setCommunityTabStatus] = useState(initialTabStatus);
  const [communityQueryString, setCommunityQueryString] = useState('');
  const [communityQueryStringState, setCommunityQueryStringState] = useState('');

  // Common page context variables
  const {
    tagNameState,
    setTagNameState,
    tagDescriptionState,
    setTagDescriptionState,
    queryStringState,
    setQueryStringState,
    ticketNameErrors,
    setTicketNameErrors,
  } = usePageContext();

  const { donationAmounts } = useDonationPage();

  // Methods
  const checksTab = [
    tagNameState === 'has-success' &&
      tagDescriptionState === 'has-success' &&
      queryStringState === 'has-success' &&
      m1TitleState === 'has-success' &&
      m1DescriptionState === 'has-success' &&
      m1CTAState === 'has-success' &&
      m2TitleState === 'has-success' &&
      m2DescriptionState === 'has-success' &&
      m2CTAState === 'has-success',
    ticketNameErrors.length === 0,
    summaryPageNameState === 'has-success' &&
      summaryDescriptionState === 'has-success' &&
      communityQueryStringState === 'has-success',
    donationAmounts.length > 0,
  ];

  const saveCommunityPageDraft = (tab: number) => {
    //Currently only checks if every field in this tab is correctly filled
    if (tab === 0) {
      if (!tagNameState) setTagNameState('has-danger');
      if (!tagDescriptionState) setTagDescriptionState('has-danger');
      if (!queryStringState) setQueryStringState('has-danger');
      if (!m1TitleState) setM1TitleState('has-danger');
      if (!m1DescriptionState) setM1DescriptionState('has-danger');
      if (!m1CTAState) setM1CTAState('has-danger');
      if (!m2TitleState) setM2TitleState('has-danger');
      if (!m2DescriptionState) setM2DescriptionState('has-danger');
      if (!m2CTAState) setM2CTAState('has-danger');
    } else if (tab === 2) {
      if (!summaryPageNameState) setSummaryPageNameState('has-danger');
      if (!summaryDescriptionState) setSummaryDescriptionState('has-danger');
      if (!communityQueryStringState) setCommunityQueryStringState('has-danger');
    }

    return checksTab[tab];
  };

  const handleSubmitCommunity = () => {
    if (checksTab.every(item => item)) {
      setCommunityTabStatus(['success', 'success', 'success', 'success']);
    } else {
      showToast('Some required fields are missing', 'error');
      checksTab.forEach((validStates, index) => {
        if (!validStates) {
          setCommunityTabStatus(prevStatus =>
            prevStatus.map((s: string, i: number) => (i === index ? 'error' : s))
          );
        }
      });
      saveCommunityPageDraft(0);
    }
  };

  useEffect(() => {
    if (getUrlParams()?.pageType === 'community') setTicketNameErrors([]);
  }, []);

  const addRow = (text: string) => {
    setRows(prevRows => [
      ...prevRows,
      {
        id: (prevRows.length + 1).toString(),
        name: text,
        price: 0,
        status: '',
        order: 0,
        quantity: 0,
      },
    ]);
  };

  const deleteRow = (ticketID: string) => {
    setTicketNameErrors(prevErrors => prevErrors.filter(error => error !== ticketID));
    setRows(prevRows => prevRows.filter(row => row.id !== ticketID));
    onErrorRow(`file-${ticketID}`);
  };

  const onErrorRow = (key: string) => {
    if (miniFilesPreview[key]) {
      URL.revokeObjectURL(miniFilesPreview[key]);

      const updatedPreview = { ...miniFilesPreview };
      delete updatedPreview[key];

      setMiniFilesPreview(updatedPreview);
    }
  };

  return (
    <CommunityPageContext.Provider
      value={{
        summaryPageName,
        summaryPageNameState,
        summaryDescription,
        summaryDescriptionState,
        m1Title,
        m1TitleState,
        m1CTA,
        m1CTAState,
        m2Title,
        m2TitleState,
        m2CTA,
        m2CTAState,
        m1Description,
        m1DescriptionState,
        m2Description,
        m2DescriptionState,
        websiteUrl,
        summaryPreview,
        selectedSummaryFile,
        videoPreview,
        miniFilesPreview,
        rows,
        communityTabStatus,
        communityQueryString,
        communityQueryStringState,
        setSummaryPageName,
        setSummaryPageNameState,
        setSummaryDescription,
        setSummaryDescriptionState,
        setM1Title,
        setM1TitleState,
        setM1CTA,
        setM1CTAState,
        setM2Title,
        setM2TitleState,
        setM2CTA,
        setM2CTAState,
        setM1Description,
        setM1DescriptionState,
        setM2Description,
        setM2DescriptionState,
        setWebsiteUrl,
        setSummaryPreview,
        setSelectedSummaryFile,
        setVideoPreview,
        setMiniFilesPreview,
        setRows,
        handleSubmitCommunity,
        setCommunityTabStatus,
        saveCommunityPageDraft,
        addRow,
        deleteRow,
        onErrorRow,
        setCommunityQueryString,
        setCommunityQueryStringState,
      }}
    >
      {children}
    </CommunityPageContext.Provider>
  );
};

export const useCommunityPage = () => {
  const context = useContext(CommunityPageContext);
  if (!context) {
    throw new Error('useCommunityPage must be used within a CommunityPageProvider');
  }
  return context;
};
