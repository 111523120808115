import { FormGroup, Input, Label } from 'reactstrap';

export const UploadFileForm = ({ preview, setPreview, onSelectFile, type }) => {
  const onError = () => setPreview('');
  const label =
    type === 'video'
      ? 'Module Video (optional). Recommended size: 1920px x 1080px.'
      : 'Custom Image. Recommended size: 1400px x 600px.';

  return (
    <FormGroup>
      <Label for={`file-${type}`}>{label}</Label>
      <br />
      <Input
        id={`file-${type}`}
        name={`file-${type}`}
        type="file"
        onChange={onSelectFile}
        className="display-none"
      />
      <Label for={`file-${type}`} className="custom-input-file">
        {`Upload ${type === 'video' ? 'Video' : 'Image'}`}
      </Label>
      <br />
      {preview &&
        (type !== 'video' ? (
          <img id={`file-${type}`} className="preview" src={preview} onError={onError} />
        ) : (
          <video id={`file-${type}`} className="preview" controls playsInline>
            <source src={preview} type="video/mp4" />
          </video>
        ))}
      {type !== 'video' && (
        <div className="category form-category">
          If you have any problems with your custom image / logo on the page, please email
          partners@oath.vote
        </div>
      )}
    </FormGroup>
  );
};
