import Select, { components } from 'react-select';
import Switch from 'react-bootstrap-switch';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { useAppDispatch } from 'helpers/hooks';
import { getRecipients } from 'store/recipients/actions';
import { usePageContext } from 'contexts/PageContext';
import { Image } from 'components/Image';

const CustomOption = props => {
  const { recipients } = usePageContext();
  const option = recipients.find(rec => rec.recipient_id === props.data.value);
  return (
    <components.Option {...props}>
      <div className="row space-between">
        <div className="row">
          <Image
            src={`https://storage.googleapis.com/oath_1/recipients/rec_${option?.misc_ids?.photo_id}.jpg`}
            alt={props.data.label}
            className="search-image"
            fallbackImgType="candidate_square"
          />
          <div className="grid">
            <span className="oath-bodysmall inkBlue">{props.data.label}</span>
            <span className="oath-bodysmall inkBlue">{option?.race?.election?.display_info}</span>
          </div>
        </div>
        <span className="search-impact">{option?.impact_score}</span>
      </div>
    </components.Option>
  );
};

export const RecipientCardsForm = () => {
  const dispatch = useAppDispatch();
  const {
    pageTypeView,
    setPageTypeView,
    impactScoreVisibility,
    setImpactScoreVisibility,
    recipientsSelected,
    setRecipientsSelected,
    removeItemRecipient,
    recipients,
  } = usePageContext();

  let timeoutId;
  const handleSearchRecipients = (search_str: string) => {
    if (search_str.length > 0) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      timeoutId = setTimeout(function () {
        dispatch(getRecipients({ search_str }));
      }, 500);
    }
  };

  return (
    <FormGroup>
      <span className="oath-eyebrow inkBlue">Recipient Cards</span>
      <Row>
        <Col md="6" className="padding0">
          <FormGroup tag="fieldset" className="margin0">
            <Label for="radio1">Page Type View</Label>
            <Row>
              <Col md="6" className="padding0">
                <FormGroup check>
                  <Input
                    id="radio1"
                    name="radio1"
                    type="radio"
                    checked={pageTypeView === 'LIST'}
                    onChange={() => setPageTypeView('LIST')}
                  />
                  <Label for="radio1" className="inkBlue">
                    List
                  </Label>
                </FormGroup>
              </Col>
              <Col md="6" className="padding0">
                <FormGroup check>
                  <Input
                    id="radio2"
                    name="radio2"
                    type="radio"
                    checked={pageTypeView === 'COMPACT'}
                    onChange={() => setPageTypeView('COMPACT')}
                  />
                  <Label for="radio2" className="inkBlue">
                    Compact
                  </Label>
                </FormGroup>
              </Col>
            </Row>
          </FormGroup>
        </Col>
        <Col md="6" className="padding0">
          <FormGroup className="margin0">
            <Label>Impact Score Visibility</Label>
            <br />
            <Switch
              value={impactScoreVisibility}
              onColor="success"
              onText="ON"
              offColor="success"
              offText="OFF"
              handleWidth={500}
              onChange={e => setImpactScoreVisibility(e.state.value)}
            />
          </FormGroup>
        </Col>
      </Row>
      <Col md="6" style={{ padding: 0 }}>
        <Label for="recipients-input">Recipients</Label>
        <Select
          id="recipients-input"
          className="react-select success"
          classNamePrefix="react-select"
          placeholder="Search"
          name="recipients-input"
          isMulti
          isSearchable
          isClearable={false}
          value={recipientsSelected}
          onChange={setRecipientsSelected}
          onInputChange={handleSearchRecipients}
          onKeyDown={e => {
            if (e.key === 'Backspace' && !e.target.value) {
              e.preventDefault();
            }
          }}
          options={recipients.map(rec => ({
            value: rec.recipient_id,
            label: rec.recipient_name,
          }))}
          components={{ Option: CustomOption }}
        />
        {recipientsSelected?.length > 0 && (
          <Row className="input-item-list">
            {recipientsSelected?.map(recipient => (
              <div
                className="input-item"
                key={recipient.value}
                onClick={() => removeItemRecipient(recipient)}
              >
                <span className="oath-utility inkBlue" key={recipient.value}>
                  {recipient.label}
                </span>
              </div>
            ))}
          </Row>
        )}
        <div className="category form-category">
          Missing a recipient you’re looking for? Please complete your page creation and email
          partners@oath.vote. Additional recipients can be added later.
        </div>
      </Col>
    </FormGroup>
  );
};
