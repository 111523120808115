import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { DescriptionForm } from './DescriptionForm';
import { useCommunityPage } from 'contexts/CommunityPageContext';
import { UploadFileForm } from './UploadFileForm';
import { usePageContext } from 'contexts/PageContext';

export const BottomPromoModuleForm = () => {
  const {
    m1Title,
    setM1Title,
    m1TitleState,
    setM1TitleState,
    m1CTA,
    setM1CTA,
    m1CTAState,
    setM1CTAState,
    m1Description,
    setM1Description,
    m1DescriptionState,
    setM1DescriptionState,
    m2Title,
    setM2Title,
    m2TitleState,
    setM2TitleState,
    m2CTA,
    setM2CTA,
    m2CTAState,
    setM2CTAState,
    m2Description,
    setM2Description,
    m2DescriptionState,
    setM2DescriptionState,
    websiteUrl,
    setWebsiteUrl,
    videoPreview,
    setVideoPreview,
  } = useCommunityPage();

  const { onSelectFile } = usePageContext();

  return (
    <>
      <span className="oath-eyebrow inkBlue">Bottom Promo Module 1</span>
      <FormGroup className={`${m1TitleState}`}>
        <Label for="module1-title">Module 1 Title *</Label>
        <Input
          id="module1-title"
          name="module1-title"
          type="text"
          value={m1Title}
          placeholder="Recommended 200 characters max"
          onChange={e => {
            setM1TitleState(e.target.value ? 'has-success' : 'has-danger');
            setM1Title(e.target.value);
          }}
        />
        {m1TitleState === 'has-danger' && (
          <Label for="module1-title" className="error">
            This field is required.
          </Label>
        )}
      </FormGroup>
      <DescriptionForm
        value={m1Description}
        setValue={setM1Description}
        valueState={m1DescriptionState}
        setValueState={setM1DescriptionState}
        label="Module 1 Description *"
        labelFor="module1-description"
      />
      <Row>
        <Col md="4" className="padding0">
          <FormGroup className={`${m1CTAState}`}>
            <Label for="module1-cta">Module 1 CTA *</Label>
            <Input
              id="module1-cta"
              name="module1-cta"
              type="text"
              value={m1CTA}
              placeholder="Enter CTA copy"
              onChange={e => {
                setM1CTAState(e.target.value ? 'has-success' : 'has-danger');
                setM1CTA(e.target.value);
              }}
            />
            {m1CTAState === 'has-danger' ? (
              <Label for="module1-cta" className="error">
                This field is required.
              </Label>
            ) : null}
          </FormGroup>
        </Col>
        <Col md="2"></Col>
        <Col md="6" className="padding0">
          <UploadFileForm
            preview={videoPreview}
            setPreview={setVideoPreview}
            onSelectFile={e => onSelectFile(e, 'video')}
            type="video"
          />
        </Col>
      </Row>
      <span className="oath-eyebrow inkBlue">Bottom Promo Module 2</span>
      <FormGroup className={`${m2TitleState}`}>
        <Label for="module2-title">Module 2 Title *</Label>
        <Input
          id="module2-title"
          name="module2-title"
          type="text"
          value={m2Title}
          placeholder="Recommended 200 characters max"
          onChange={e => {
            setM2TitleState(e.target.value ? 'has-success' : 'has-danger');
            setM2Title(e.target.value);
          }}
        />
        {m2TitleState === 'has-danger' ? (
          <Label for="module2-title" className="error">
            This field is required.
          </Label>
        ) : null}
      </FormGroup>
      <DescriptionForm
        value={m2Description}
        setValue={setM2Description}
        valueState={m2DescriptionState}
        setValueState={setM2DescriptionState}
        label="Module 2 Description *"
        labelFor="module2-description"
      />
      <Row>
        <Col md="4" className="padding0">
          <FormGroup className={`${m2CTAState}`}>
            <Label for="module2-cta">Module 2 CTA *</Label>
            <Input
              id="module2-cta"
              name="module2-cta"
              type="text"
              value={m2CTA}
              placeholder="Enter CTA copy"
              onChange={e => {
                setM2CTAState(e.target.value ? 'has-success' : 'has-danger');
                setM2CTA(e.target.value);
              }}
            />
            {m2CTAState === 'has-danger' ? (
              <Label for="module2-cta" className="error">
                This field is required.
              </Label>
            ) : null}
          </FormGroup>
        </Col>
        <Col md="2"></Col>
        <Col md="4" className="padding0">
          <FormGroup>
            <Label for="website-url">URL</Label>
            <Input
              id="website-url"
              name="website-url"
              type="text"
              value={websiteUrl}
              placeholder="Website URL"
              onChange={e => setWebsiteUrl(e.target.value)}
            />
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};
