import { AnimatePresence, motion } from 'framer-motion';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { getIsSuper } from 'config/permissions';
import { usePageContext } from 'contexts/PageContext';
import Loader from 'components/Loader';
import { CTAForm } from './FormGroups/CTAForm';
import { SubcopyForm } from './FormGroups/SubcopyForm';
import { PageNameForm } from './FormGroups/PageNameForm';
import { LocationForm } from './FormGroups/LocationForm';
import { WebsiteURLForm } from './FormGroups/WebsiteURLForm';
import { DatePickerForm } from './FormGroups/DatePickerForm';
import { UploadFileForm } from './FormGroups/UploadFileForm';
import { ColorPickerForm } from './FormGroups/ColorPickerForm';
import { TimePickersForm } from './FormGroups/TimePickersForm';
import { DescriptionForm } from './FormGroups/DescriptionForm';
import { DonationTicketForm } from './FormGroups/DonationTicketForm';
import { RecipientCardsForm } from './FormGroups/RecipientCardsForm';
import { DonationAmountsForm } from './FormGroups/DonationAmountsForm';
import { BottomPromoModuleForm } from './FormGroups/BottomPromoModuleForm';
import { useEventPage } from 'contexts/EventPageContext';
import { useDonationPage } from 'contexts/DonationPageContext';
import { SummaryDonationForm } from './FormGroups/SummaryDonationForm';
import { DonationEventCardsForm } from './FormGroups/DonationEventCardsForm';
import { useCommunityPage } from 'contexts/CommunityPageContext';

export const PageAccordion = ({
  index,
  itemVisible,
  handleClickAccordion,
  pagesState,
  pageType,
}) => {
  const { handleSubmitEvent } = useEventPage();
  const { handleSubmitDonation } = useDonationPage();
  const { handleSubmitCommunity } = useCommunityPage();
  const {
    editPageSelected,
    isPageDuplicated,
    tagDescription,
    setTagDescription,
    tagDescriptionState,
    setTagDescriptionState,
    preview,
    setPreview,
    onSelectFile,
  } = usePageContext();

  const saveForm = () => {
    if (pageType === 'donate') handleSubmitDonation();
    if (pageType === 'event') handleSubmitEvent();
    // if (pageType === 'community') handleSubmitCommunity();
  };

  return (
    <AnimatePresence>
      {itemVisible === index && (
        <motion.div
          initial={{ height: 0 }}
          animate={{ height: 'auto' }}
          exit={{ height: 0 }}
          transition={{ duration: 0.5, ease: 'linear' }}
          className="motion-card-container"
        >
          {index === 0 ? (
            <Card>
              <CardBody className="card-body-page-detail">
                <span className="oath-eyebrow inkBlue">Left-Side Panel</span>
                <PageNameForm disabled={!getIsSuper() && editPageSelected && !isPageDuplicated} />
                {pageType === 'event' && <SubcopyForm />}
                {pageType === 'event' && (
                  <>
                    <Row>
                      <Col md="4" className="padding0">
                        <DatePickerForm />
                      </Col>
                      <Col md="2" />
                      <Col md="6" className="padding0">
                        <LocationForm />
                      </Col>
                    </Row>
                    <TimePickersForm />
                  </>
                )}
                <Row>
                  {/* In the meantime only for super admins */}
                  {getIsSuper() && (
                    <Col md="6" className="padding0">
                      <UploadFileForm
                        preview={preview}
                        setPreview={setPreview}
                        onSelectFile={e => onSelectFile(e, 'tag')}
                        type="tag"
                      />
                    </Col>
                  )}
                  <Col md="6" className="padding0">
                    <ColorPickerForm />
                  </Col>
                </Row>
                {((tagDescription && editPageSelected) || !editPageSelected) && (
                  <DescriptionForm
                    value={tagDescription}
                    setValue={setTagDescription}
                    valueState={tagDescriptionState}
                    setValueState={setTagDescriptionState}
                    label="Page Description *"
                    labelFor="description-input"
                  />
                )}
                <WebsiteURLForm type="tag" pageType={pageType} />
                {pageType === 'community' ? (
                  <BottomPromoModuleForm />
                ) : (
                  <>
                    <RecipientCardsForm />
                    <CTAForm
                      title="Additional customization [optional]"
                      label="Page Call to Action"
                    />
                  </>
                )}
                <Button
                  className="btn-inkBlue-inverted"
                  onClick={() => handleClickAccordion(index + 1)}
                  disabled={pagesState.isLoading}
                  style={{ minWidth: 200 }}
                >
                  Next
                </Button>
              </CardBody>
            </Card>
          ) : index === 1 ? (
            <Card>
              <CardBody className="card-body-page-detail">
                {pageType === 'donate' && <DonationAmountsForm />}
                {pageType === 'event' && <DonationTicketForm />}
                {pageType === 'community' && <DonationEventCardsForm />}
                {pageType !== 'community' ? (
                  <Button
                    className="btn-inkBlue row"
                    onClick={saveForm}
                    disabled={pagesState.isLoading}
                  >
                    Publish
                    {pagesState.isLoading && <Loader type="spinner" width={16} height={16} />}
                  </Button>
                ) : (
                  <Button
                    className="btn-inkBlue-inverted"
                    onClick={() => handleClickAccordion(index + 1)}
                    disabled={pagesState.isLoading}
                    style={{ minWidth: 200 }}
                  >
                    Next
                  </Button>
                )}
              </CardBody>
            </Card>
          ) : index === 2 ? (
            <Card>
              <CardBody className="card-body-page-detail">
                <CTAForm title="Community Page CTA" label="Main CTA" />
                <SummaryDonationForm />
                <WebsiteURLForm type="community" pageType={pageType} />
                <RecipientCardsForm />
                <Button
                  className="btn-inkBlue-inverted"
                  onClick={() => handleClickAccordion(index + 1)}
                  disabled={pagesState.isLoading}
                  style={{ minWidth: 200 }}
                >
                  Next
                </Button>
              </CardBody>
            </Card>
          ) : (
            index === 3 && (
              <Card>
                <CardBody className="card-body-page-detail">
                  <DonationAmountsForm />
                  <Button
                    className="btn-inkBlue row"
                    onClick={saveForm}
                    disabled={pagesState.isLoading}
                  >
                    Publish
                    {pagesState.isLoading && <Loader type="spinner" width={16} height={16} />}
                  </Button>
                </CardBody>
              </Card>
            )
          )}
        </motion.div>
      )}
    </AnimatePresence>
  );
};
