export const filterDonationOptions = [
  {
    label: 'By Donation',
    value: new Date(),
  },
  {
    label: 'Custom',
    value: new Date(),
  },
];

export const filterPagesOptions = [
  {
    label: 'All Pages',
    value: 'all',
  },
  {
    label: 'Active',
    value: 'ACTIVE',
  },
  {
    label: 'Paused',
    value: 'PAUSED',
  },
  {
    label: 'Archive',
    value: 'ARCHIVED',
  },
];

export const filterDateOptions = [
  {
    label: 'Today',
    value: new Date(),
  },
  {
    label: 'Last 7 days',
    value: new Date().setDate(new Date().getDate() - 6),
  },
  {
    label: 'Last 30 days',
    value: new Date().setDate(new Date().getDate() - 30),
  },
  {
    label: 'Last 90 days',
    value: new Date().setDate(new Date().getDate() - 90),
  },
  {
    label: 'Last 365 days',
    value: new Date().setDate(new Date().getDate() - 365),
  },
  {
    label: 'Custom',
    value: new Date(),
  },
];
