import { FC, useState } from 'react';
import Icon, { IconNames } from 'components/Icon';
import { useAppDispatch } from 'helpers/hooks';
import { setUserDates } from 'store/user/actions';

interface Props {
  setIsCustom: (val: boolean) => void;
  labels: { label: string; value: string | Date | number }[];
}

export const DateFilters: FC<Props> = ({ setIsCustom, labels }) => {
  const dispatch = useAppDispatch();

  const handleChangeDates = startDate => {
    const start = new Date(startDate).setHours(0, 0, 0, 0);
    const end = new Date().setHours(23, 59, 59, 999);

    dispatch(setUserDates(start, end, true));
  };

  const [activeIndex, setActiveIndex] = useState(0);
  const handleSelect = (option, index) => {
    setActiveIndex(index);
    setIsCustom(index === labels.length - 1);
    if (index !== labels.length - 1) handleChangeDates(option.value);
  };

  return (
    <div className="date-filter-container">
      {labels.map((option, index) => (
        <button
          key={index}
          className={`date-filter-item ${activeIndex === index ? 'active' : ''}`}
          onClick={() => handleSelect(option, index)}
        >
          <span className="oath-body inkBlue">{option.label}</span>
          {option.label === 'Custom' && (
            <Icon
              name={activeIndex === index ? IconNames.ChevronUp : IconNames.ChevronDown}
              size={18}
            />
          )}
        </button>
      ))}
    </div>
  );
};
