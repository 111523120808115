import { IconNames } from 'components/Icon';

export interface ITableColumn {
  Header: string;
  accessor: string;
  sortable?: boolean;
  filterable?: boolean;
  visible: boolean;
}

export const summaryRecipientColumns = [
  'Recipient Name',
  // 'Status',
  // 'Impact Score',
  'Amount Raised',
  '# of Donations',
];

export const summaryPageColumns = [
  'Page Name',
  'Amount Donated',
  '# of Donations',
  // 'Status',
  // 'Latest Donation',
];

const caseInsensitiveSort = (rowA, rowB, columnId) => {
  const valueA = rowA.values[columnId]?.toLowerCase() || '';
  const valueB = rowB.values[columnId]?.toLowerCase() || '';
  return valueA.localeCompare(valueB);
};

export const eventPageColumns = [
  {
    Header: 'Updated Date',
    accessor: 'updatedDate',
    visible: true,
    sortType: caseInsensitiveSort,
  },
  { Header: 'Page Name', accessor: 'pageName', visible: true, sortType: caseInsensitiveSort },
  { Header: 'Website URL', accessor: 'webUrl', visible: true, sortType: caseInsensitiveSort },
  { Header: 'Event Date', accessor: 'eventDate', visible: true, sortType: caseInsensitiveSort },
  { Header: 'Actions', accessor: 'actions', visible: true, sortable: false, filterable: false },
];

export const donatePageColumns = [
  {
    Header: 'Updated Date',
    accessor: 'updatedDate',
    visible: true,
    sortType: caseInsensitiveSort,
  },
  { Header: 'Page Name', accessor: 'pageName', visible: true, sortType: caseInsensitiveSort },
  { Header: 'Website URL', accessor: 'webUrl', visible: true, sortType: caseInsensitiveSort },
  { Header: 'Actions', accessor: 'actions', visible: true, sortable: false, filterable: false },
];

export const donorColumns = [
  { Header: 'Donation Date', accessor: 'date', visible: true },
  { Header: 'Donor Name', accessor: 'name', visible: true },
  { Header: 'Amount Donated', accessor: 'amount', visible: true },
  { Header: 'Page', accessor: 'page', visible: true },
  { Header: 'Reference Code', accessor: 'ref', visible: true },
  { Header: 'Email', accessor: 'email', visible: false },
  { Header: 'City', accessor: 'city', visible: false },
  { Header: 'State', accessor: 'state', visible: false },
  { Header: 'Transaction ID', accessor: 'transaction_id', visible: false },
];

export const buttonTypes = {
  edit: { icon: IconNames.Edit },
  delete: { icon: IconNames.Trash },
  preview: { icon: IconNames.Eye },
  duplicate: { icon: IconNames.Copy },
};
