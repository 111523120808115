import { usePageContext } from 'contexts/PageContext';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { UploadFileForm } from './UploadFileForm';
import { ColorPickerForm } from './ColorPickerForm';
import { DescriptionForm } from './DescriptionForm';
import { useCommunityPage } from 'contexts/CommunityPageContext';

export const SummaryDonationForm = () => {
  const { onSelectFile } = usePageContext();

  const {
    summaryPageName,
    setSummaryPageName,
    setSummaryPageNameState,
    summaryPageNameState,
    summaryDescription,
    setSummaryDescription,
    setSummaryDescriptionState,
    summaryDescriptionState,
    summaryPreview,
    setSummaryPreview,
  } = useCommunityPage();

  return (
    <>
      <span className="oath-eyebrow inkBlue">Community Summary Donation Page</span>
      <FormGroup className={summaryPageNameState}>
        <Label for="summary-page-name-input">Page Name *</Label>
        <Input
          id="summary-page-name-input"
          name="summary-page-name-input"
          type="text"
          value={summaryPageName}
          onChange={e => {
            setSummaryPageNameState(e.target.value ? 'has-success' : 'has-danger');
            setSummaryPageName(e.target.value);
          }}
        />
        {summaryPageNameState === 'has-danger' ? (
          <Label for="summary-page-name-input" className="error">
            This field is required.
          </Label>
        ) : null}
      </FormGroup>
      <FormGroup>
        <Row>
          <Col md="6" className="padding0">
            <UploadFileForm
              preview={summaryPreview}
              setPreview={setSummaryPreview}
              onSelectFile={e => onSelectFile(e, 'summary')}
              type="summary"
            />
          </Col>
          <Col md="6" className="padding0">
            <ColorPickerForm />
          </Col>
        </Row>
      </FormGroup>
      <DescriptionForm
        value={summaryDescription}
        setValue={setSummaryDescription}
        valueState={summaryDescriptionState}
        setValueState={setSummaryDescriptionState}
        label="Page Description *"
        labelFor="description-input"
      />
    </>
  );
};
