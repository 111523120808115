export const numberWithDot = (x: number | string) => {
  const withComma = x?.toString().replace(',', '.');

  return withComma?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || ' ...';
};

export const percentDiff = (val1: number, val2: number) => {
  if (val1 && val2) return ((val1 * 100) / val2 - 100).toFixed(2);
  return '-';
};

export const sumAmounts = (array: number[]) => {
  return array?.reduce((accumulator, value) => {
    return accumulator + value;
  }, 0);
};

export const limitChars = (value, limit) => {
  if (value?.length > limit) {
    return `${value.substring(0, limit)}...`;
  } else {
    return value;
  }
};

export const transformToUrl = (value: string) => {
  return value.replace(/ /g, '-');
};

export function wait(timeout) {
  return new Promise(resolve => {
    setTimeout(resolve, timeout);
  });
}

export const getUrlSearchParamsObj = () => new URL(window.location.href).searchParams;

export const getUrlParams = () => {
  const searchParams = getUrlSearchParamsObj();

  return {
    tagId: searchParams?.get('tag_id'),
    copy: searchParams?.get('copy'),
    pageType: searchParams?.get('page_type'),
  };
};

export const isKeyInObject = (key: string, obj: object) => Object.keys(obj).includes(key);

export const getInitials = (fullName: string) => {
  const words = fullName.trim().split(/\s+/);

  // Get the first two words and their initials
  const initials = words
    .slice(0, 2)
    .map(word => word[0])
    .join('');

  return initials.toUpperCase();
};

export const formatNumberAsCurrency = (num: number, options: object = {}) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    //@ts-ignore
    trailingZeroDisplay: 'stripIfInteger',
    ...options,
  }).format(num);
};

export const parseCurrency = (value: string): number =>
  parseFloat(value.replace(/[^0-9.-]+/g, '')) || 0;
