import { useEffect, useRef, useState } from 'react';
import routes from 'routes';
import { Form, Label, Row } from 'reactstrap';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { has_permission } from 'config/permissions';
import { usePageContext } from 'contexts/PageContext';
import colors from 'constants/colors';
import Loader from 'components/Loader';
import Sidebar from 'components/Sidebar';
import { showToast } from 'components/Toast';
import Icon, { IconNames } from 'components/Icon';
import AdminNavbar from 'components/Navbars/AdminNavbar';
import { wait, getUrlParams } from 'helpers/utils';
import { useAppDispatch, useAppSelector } from 'helpers/hooks';
import { usePagesDetail } from 'hooks/usePagesDetail';
import { getPagesState } from 'selectors/pages';
import { getRecipientsState } from 'selectors/recipients';
import { getAccessToken, getClientID } from 'services/storage';
import { uploadFile, getAllPages, getPageDetail, getPages } from 'store/pages/actions';
import { pageTypes } from './constants';
import { PageAccordion } from './PageAccordion';
import { PageDetailHeader } from './PageDetailHeader';
import { useDonationPage } from 'contexts/DonationPageContext';
import { useEventPage } from 'contexts/EventPageContext';
import { useCommunityPage } from 'contexts/CommunityPageContext';
import { getUserState } from 'selectors/user';

const PagesDetail = () => {
  const mainPanel = useRef<any>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [pageLoaded, setPageLoaded] = useState(false);
  const [pageType, setPageType] = useState(getUrlParams()?.pageType || 'donate');

  const { recipientsState, pagesState, userState } = useAppSelector(state => ({
    recipientsState: getRecipientsState(state),
    pagesState: getPagesState(state),
    userState: getUserState(state),
  }));

  const {
    selectedFile,
    selectedSummaryFile,
    selectedMiniFiles,
    selectedVideoFile,
    checkingWebsite,
    editPageSelected,
    setEditPageSelected,
    setIsPageDuplicated,
    itemVisible,
    setItemVisible,
    checkingTagName,
  } = usePageContext();

  const { saveEventPageDraft, setEventTabStatus, eventTabStatus } = useEventPage();
  const { saveDonationPageDraft, setDonationTabStatus, donationTabStatus } = useDonationPage();
  const { saveCommunityPageDraft, setCommunityTabStatus, communityTabStatus } = useCommunityPage();

  const saveDraft =
    pageType === 'donate'
      ? saveDonationPageDraft
      : pageType === 'event'
        ? saveEventPageDraft
        : saveCommunityPageDraft;
  const tabStatus =
    pageType === 'donate'
      ? donationTabStatus
      : pageType === 'event'
        ? eventTabStatus
        : communityTabStatus;
  const setTabStatus =
    pageType === 'donate'
      ? setDonationTabStatus
      : pageType === 'event'
        ? setEventTabStatus
        : setCommunityTabStatus;

  const {
    initializeEditPages,
    initializePreviewFile,
    initializeRecipients,
    checkNotRepeatedValues,
  } = usePagesDetail();

  useEffect(() => {
    setTabStatus(prevStatus => ['in-progress', ...prevStatus.slice(1)]);
  }, []);

  useEffect(() => {
    if (!pagesState.isLoading) {
      if (pagesState.data?.pageCreated) {
        showToast(
          `Success! Your page has been ${editPageSelected ? 'updated' : 'created'}`,
          'success'
        );
        //Upload image after creating the page so we have a tag id.
        if (selectedFile) {
          const customName = `tag_${
            editPageSelected ? editPageSelected[0].id : pagesState?.data?.pageCreated?.page_id
          }.png`;
          const renamedFile = new File([selectedFile], customName, { type: selectedFile.type });
          dispatch(uploadFile(renamedFile));
        }
        wait(2000).then(() => navigate('/donate_pages'));
      }
      if (pagesState.data?.clientPages?.pages) {
        const selected = pagesState.data.clientPages.pages.filter(
          page => page.id === parseInt(getUrlParams().tagId || '')
        );
        if (selected?.length > 0) {
          if (getUrlParams()?.tagId && !pageLoaded) {
            setPageLoaded(true);
            if (selected.length === 0) navigate('/donate_pages');
            if (getUrlParams().copy !== null) setIsPageDuplicated(true);
            dispatch(
              getPageDetail(
                selected[0].query_str,
                selected[0].page_category === 'event' ? 'event' : 'donate'
              )
            );
            setPageType(selected[0].page_category === 'event' ? 'event' : 'donate');
          } else if (pagesState.data.pageDetail && pageLoaded) {
            setEditPageSelected(
              pagesState.data.pageDetail[selected[0].page_category === 'event' ? 'events' : 'pages']
            );
          }
        }
      }
    }
  }, [pagesState]);

  useEffect(() => {
    if (getClientID() && getAccessToken()) {
      if (!pagesState.isLoading) {
        dispatch(getPages(getClientID() || '', pageType));
        if (!pagesState.data.allPages) dispatch(getAllPages());
      }
    }
  }, [userState]);

  useEffect(() => initializeEditPages(), [editPageSelected]);
  useEffect(() => initializeRecipients(recipientsState), [recipientsState]);
  useEffect(() => checkNotRepeatedValues(pagesState), [checkingWebsite, checkingTagName]);
  useEffect(() => {
    const files = [
      { file: selectedFile, type: 'tag' },
      { file: selectedSummaryFile, type: 'summary' },
      { file: selectedVideoFile, type: 'video' },
      { file: selectedMiniFiles, type: 'file' },
    ];

    files.forEach(({ file, type }) => initializePreviewFile(file, type));
  }, [selectedFile, selectedSummaryFile, selectedVideoFile, selectedMiniFiles]);

  const updateStatus = (item: number, status: string) => {
    setTabStatus(prevStatus =>
      prevStatus.map((s: string, index: number) => (index === item ? status : s))
    );
  };

  const handleClickAccordion = (item: number, type: 'tab' | 'next') => {
    setItemVisible(prev => (prev === item ? -1 : item));

    if (type === 'tab') {
      if (tabStatus[item] === '') {
        updateStatus(item, 'in-progress');
      } else {
        const status = saveDraft(item) ? 'success' : 'error';
        updateStatus(item, status);
      }
    } else if (type === 'next') {
      const prevStatus = saveDraft(item - 1) ? 'success' : 'error';
      updateStatus(item - 1, prevStatus);

      if (tabStatus[item] === '') {
        updateStatus(item, 'in-progress');
      } else {
        const status = saveDraft(item) ? 'success' : 'error';
        updateStatus(item, status);
      }
    }
  };

  return (
    <>
      {pagesState.isLoading && <Loader type="dots" />}
      <div className="wrapper">
        <Sidebar routes={routes} />
        <div className="main-panel" ref={mainPanel}>
          <AdminNavbar />
          <PageDetailHeader pageType={pageType} />
          {!has_permission('SCREEN_VIEW_PERMISSION', 'R') ? (
            <div className="content">
              <h1 className="title-permission">
                Sorry, you don't have permission to see this screen.
              </h1>
            </div>
          ) : (
            <div className="content-full-width">
              <Form id="RegisterValidation">
                {pageTypes[pageType].map((type, index) => (
                  <div key={index}>
                    <div
                      className={classNames('card-accordion-header', {
                        active: itemVisible === index,
                      })}
                      onClick={() => handleClickAccordion(index, 'tab')}
                    >
                      <Row className="align-center">
                        <span className="oath-body">
                          {index + 1}. {type}
                        </span>
                        {tabStatus[index] === 'in-progress' ? (
                          <Label className="status-tab in-progress">In Progress</Label>
                        ) : tabStatus[index] === 'error' ? (
                          <Label className="status-tab error">Error - Please Fix</Label>
                        ) : (
                          tabStatus[index] === 'success' && (
                            <div className="status-tab success">
                              <Icon name={IconNames.Check} color={colors.white} size={12} />
                            </div>
                          )
                        )}
                      </Row>
                      <Icon
                        name={itemVisible === index ? IconNames.ChevronUp : IconNames.ChevronDown}
                        color={itemVisible === index ? colors.oathBlue : colors.inkBlue}
                      />
                    </div>
                    <PageAccordion
                      index={index}
                      itemVisible={itemVisible}
                      handleClickAccordion={index => handleClickAccordion(index, 'next')}
                      pagesState={pagesState}
                      pageType={pageType}
                    />
                  </div>
                ))}
              </Form>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default PagesDetail;
