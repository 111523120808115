import { ReactElement, useEffect, useRef, useState } from 'react';
import routes from 'routes';
import classNames from 'classnames';
import { Row, Col, Button } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { ENV_CONFIG } from 'config/environment';
import { has_permission } from 'config/permissions';
import { eventPageColumns, donatePageColumns } from 'constants/tables';
import Loader from 'components/Loader';
import Sidebar from 'components/Sidebar';
import { ModalComponent } from 'components/Modal';
import AdminNavbar from 'components/Navbars/AdminNavbar';
import { PagesFilters } from 'components/Filters/PagesFilters';
import { CustomTable, tableButton } from 'components/CustomTable';
import { RecentActivity, RecentActivityClickable } from 'components/RecentActivity';
import { numberWithDot } from 'helpers/utils';
import { useAppDispatch, useAppSelector } from 'helpers/hooks';
import { getUserState } from 'selectors/user';
import { getDonorData } from 'selectors/donors';
import { getPagesState } from 'selectors/pages';
import { getClientID, getClientName, getAccessToken } from 'services/storage';
import { getAllPages, getPageDetail, getPages, pageCleanUp, updatePage } from 'store/pages/actions';
import { inputDate, tableDate } from 'helpers/date';

interface IData {
  id: number;
  pageName: string;
  webUrl: string;
  updatedDate: string;
  eventDate?: string;
  actions: ReactElement;
}
const Pages = ({ category }) => {
  const mainPanel = useRef<any>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [dataState, setDataState] = useState(Array<IData>());
  const [filterSelected, setFilterSelected] = useState('all');
  const [recentActivityMini, setRecentActivityMini] = useState(false);
  const [pageTypeSelected, setPageTypeSelected] = useState('');
  const [pageColumns, setPageColumns] = useState(donatePageColumns);
  const [popupType, setPopupType] = useState('');
  const [isDeleting, setDeleting] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const closePopup = () => setIsOpen(false);
  const createPage = () => {
    setIsOpen(true);
    setPopupType('create');
  };

  const { pagesState, userState, donors } = useAppSelector(state => ({
    pagesState: getPagesState(state),
    userState: getUserState(state),
    donors: getDonorData(state),
  }));

  useEffect(() => {
    if (getClientID() && getAccessToken()) {
      if (!pagesState.isLoading) {
        if (!pagesState.data.allPages) dispatch(getAllPages());
      }
    }
  }, [userState]);

  useEffect(() => {
    setPageColumns(category === 'donate' ? donatePageColumns : eventPageColumns);
    if (getClientID() && getAccessToken()) {
      dispatch(getPages(getClientID() || '', category));
    }
  }, [category, getClientID()]);

  useEffect(() => {
    if (!pagesState.isLoading && pagesState.data?.clientPages?.pages) {
      if (pagesState.data?.pageCreated) dispatch(getPages(getClientID() || '', category));
      if (pagesState.data.pageDetail && isDeleting) {
        const pageBody = category === 'event' ? 'events' : 'pages';
        setDeleting(false);
        const updateBody = {
          client_id: getClientID() || '',
          page: {
            ...pagesState.data.pageDetail[pageBody][0],
            is_enabled: false,
          },
        };
        dispatch(updatePage(updateBody, category));
        closePopup();
      }
      setDataState(
        pagesState.data.clientPages.pages
          .filter(page =>
            filterSelected === 'all' ? page.is_enabled : page.status === filterSelected
          )
          ?.map((prop, key) => {
            return {
              id: key,
              pageName: prop.value,
              webUrl:
                category === 'event'
                  ? `${ENV_CONFIG().EVENT_PAGE}${prop.query_str}`
                  : `${ENV_CONFIG().PARTNER_PAGE}${prop.query_str}`,
              updatedDate: inputDate(new Date(prop.updated_date).getTime()),
              eventDate: tableDate(prop?.start_datetime),
              actions: (
                <div className="grid-column">
                  <div className="table-action-row">
                    {has_permission('PAGES_ROUTE', 'U') &&
                      tableButton(handleUpdate, 'edit', prop.id)}
                    {tableButton(handleUpdate, 'preview', prop.query_str)}
                  </div>
                  <div className="table-action-row margin0">
                    {has_permission('PAGES_ROUTE', 'U') &&
                      tableButton(handleUpdate, 'duplicate', prop.id)}
                    {has_permission('PAGES_ROUTE', 'D') &&
                      tableButton(handleUpdate, 'delete', prop.id)}
                  </div>
                </div>
              ),
            };
          })
      );
    }
  }, [pagesState, filterSelected, category]);

  useEffect(() => {
    dispatch(pageCleanUp());
  }, []);

  const pageTypeButtons = [
    {
      type: 'donate',
      img: `${ENV_CONFIG().CLOUD_STORAGE.URL}/static_content/donation-page.jpg`,
      title: 'Donation Page',
      description: 'Drive action to 1 main cause/issue',
    },
    {
      type: 'event',
      img: `${ENV_CONFIG().CLOUD_STORAGE.URL}/static_content/event-page.jpg`,
      title: 'Event Page',
      description: 'Host a fundraising event',
    },
    // {
    //   type: 'community',
    //   img: `${ENV_CONFIG().CLOUD_STORAGE.URL}/static_content/community-page.jpg`,
    //   title: 'Community Page',
    //   description: 'Drive action to multiple issues',
    // },
  ];

  const handleDelete = () => {
    const tagID = parseInt(popupType.split('-')[1]);
    const selected = pagesState?.data?.clientPages[category]?.pages?.find(
      page => page.id === tagID
    );
    dispatch(getPageDetail(selected?.query_str || '', category));
    setDeleting(true);
  };

  const handleUpdate = (type: string, param: string | number) => {
    const previewURL =
      category === 'event'
        ? `${ENV_CONFIG().EVENT_PAGE}${param}`
        : `${ENV_CONFIG().PARTNER_PAGE}${param}`;
    if (type === 'preview') window.open(previewURL, '_blank');
    if (type === 'edit') navigate(`/pages_detail?tag_id=${param}&page_type=${category}`);
    if (type === 'duplicate') navigate(`/pages_detail?tag_id=${param}&page_type=${category}&copy`);
    if (type === 'delete') {
      setIsOpen(true);
      setPopupType(`delete-${param}`);
    }
  };

  return (
    <>
      <ModalComponent isOpen={isOpen} closeModal={closePopup}>
        <div className="grid-column">
          {popupType === 'create' ? (
            <>
              <span className="create-page-title">Create a Page</span>
              <span className="oath-body inkBlue">Select the type of page you want to create:</span>
              {pageTypeButtons.map((page, index) => (
                <div
                  key={index}
                  className={classNames('popup-new-page', {
                    active: pageTypeSelected === page.type,
                  })}
                  onClick={() => setPageTypeSelected(page.type)}
                >
                  <img src={page.img} alt={page.type} />
                  <div className="grid-column">
                    <span className="oath-eyebrow inkBlue">{page.title}</span>
                    <span className="oath-bodysmall inkBlue">{page.description}</span>
                  </div>
                </div>
              ))}
              <Button
                className="btn-inkBlue"
                onClick={() => navigate(`/pages_detail?page_type=${pageTypeSelected}`)}
              >
                Confirm
              </Button>
            </>
          ) : (
            <>
              <span className="oath-eyebrow inkBlue">Delete Page</span>
              <span className="oath-body inkBlue">
                Are you sure you would like to delete this page? This can’t be undone.
              </span>
              <Button block className="btn-inkBlue" onClick={handleDelete}>
                Delete
              </Button>
            </>
          )}
          <span className="oath-bodysmall link-text gray" onClick={closePopup}>
            Cancel
          </span>
        </div>
      </ModalComponent>
      {pagesState.isLoading && <Loader type="dots" />}
      <div className="wrapper">
        <Sidebar routes={routes} />
        <div className="main-panel" ref={mainPanel}>
          <AdminNavbar />
          <Row className="filters-row">
            <PagesFilters setFilterSelected={setFilterSelected} />
            <RecentActivityClickable
              isMini={recentActivityMini}
              setIsMini={setRecentActivityMini}
            />
          </Row>
          {!has_permission('SCREEN_VIEW_PERMISSION', 'R') ? (
            <div className="content">
              <h1 className="title-permission">
                Sorry, you don't have permission to see this screen.
              </h1>
            </div>
          ) : (
            <div className={`content ${recentActivityMini ? 'content-mini' : ''}`}>
              <RecentActivity isMini={recentActivityMini} donors={donors} />
              <Row className="custom-header-container">
                <Col lg="9" md="8" sm="12" className="grid-column">
                  <span className="oath-h3 inkBlue">{getClientName()} Pages</span>
                  <span className="oath-body inkBlue">
                    {numberWithDot(dataState?.length)} Total
                  </span>
                </Col>
                {has_permission('PAGES_ROUTE', 'C') && (
                  <Col lg="3" md="4" sm="12" className="new-page-button">
                    <Button block className="btn-inkBlue" onClick={createPage}>
                      Create New Page
                    </Button>
                  </Col>
                )}
              </Row>
              <Row>
                <Col lg="12">
                  <CustomTable
                    subtitle="A full list of your pages can be found below. Reach out to partners@oath.vote if you need support."
                    data={dataState}
                    columns={pageColumns}
                    withPagination
                  />
                </Col>
              </Row>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Pages;
