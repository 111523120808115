export const pageTypes = {
  donate: ['Donation Page Design', 'Donation Options'],
  event: ['Event Page Design', 'Donation Ticket Options'],
  community: [
    'Community Fundraising Page Design',
    'Fundraising Goals',
    'Summary CTA & Page',
    'Summary Donation Page Options',
  ],
};

export interface IRecipient {
  value: string;
  label: string;
}
