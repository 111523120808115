import React from 'react';
import { Input, InputGroup, InputGroupText } from 'reactstrap';

type QuantityInputProps = {
  value: string | number;
  onChange: (value: number) => void;
  min?: number;
  max?: number;
  disabled?: boolean;
};

const QuantityInput: React.FC<QuantityInputProps> = ({
  value,
  onChange,
  min = 0,
  max = Infinity,
  disabled,
}) => {
  const handleIncrement = () => {
    if (typeof value === 'number' && value < max && !disabled) {
      onChange(value + 1);
    }
  };

  const handleDecrement = () => {
    if (typeof value === 'number' && value > min && !disabled) {
      onChange(value - 1);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = parseInt(e.target.value, 10);
    if (!isNaN(inputValue) && inputValue >= min) {
      if (inputValue >= max) onChange(max);
      else onChange(inputValue);
    }
  };

  return (
    <InputGroup className="ticket-input small form-control ticket-group">
      {!disabled && (
        <InputGroupText onClick={handleDecrement} className="quantity-input">
          -
        </InputGroupText>
      )}
      <Input
        value={`${value}${value === 999 ? '+' : ''}`}
        onChange={handleChange}
        className="quantity-input-text"
        disabled={disabled}
      />
      {!disabled && (
        <InputGroupText onClick={handleIncrement} className="quantity-input">
          +
        </InputGroupText>
      )}
    </InputGroup>
  );
};

export default QuantityInput;
