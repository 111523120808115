import { wrap } from 'framer-motion';
import { useState } from 'react';

export const useCarousel = ({ carouselComponents }) => {
  const [[itemCount, direction], setItemCount] = useState([0, 0]);
  const activeItemIndex = wrap(0, carouselComponents.length, itemCount);

  const sliderVariants = {
    incoming: (direction: number) => ({
      x: direction > 0 ? '100%' : '-100%',
      scale: 1.2,
      opacity: 0,
    }),
    active: { x: 0, scale: 1, opacity: 1 },
    exit: (direction: number) => ({
      x: direction > 0 ? '-100%' : '100%',
      scale: 1,
      opacity: 0.2,
    }),
  };

  const sliderTransition = {
    duration: 1,
    ease: [0.56, 0.03, 0.12, 1.04],
  };

  const swipeToNextItem = (swipeDirection: number) => {
    setItemCount([itemCount + swipeDirection, swipeDirection]);
  };

  const dragEndHandler = dragInfo => {
    const draggedDistance = dragInfo.offset.x;
    const swipeThreshold = 50;
    if (draggedDistance > swipeThreshold) {
      swipeToNextItem(-1);
    } else if (draggedDistance < -swipeThreshold) {
      swipeToNextItem(1);
    }
  };

  const skipToItem = (itemId: number) => {
    let changeDirection = 0;
    if (itemId > activeItemIndex) {
      changeDirection = 1;
    } else if (itemId < activeItemIndex) {
      changeDirection = -1;
    }
    setItemCount([itemId, changeDirection]);
  };

  return {
    itemCount,
    direction,
    sliderVariants,
    sliderTransition,
    dragEndHandler,
    skipToItem,
    activeItemIndex,
  };
};
