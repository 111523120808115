import { useDonationPage } from 'contexts/DonationPageContext';
import { Row, FormGroup, Input, Label } from 'reactstrap';

export const DonationAmountsForm = () => {
  const {
    donationAmounts,
    tempDonationAmount,
    setTempDonationAmount,
    addDonationAmount,
    removeItemAmount,
  } = useDonationPage();

  return (
    <FormGroup className={`${donationAmounts.length === 0 && 'has-danger'}`}>
      <Label for="donation-amounts">
        Appears after a donor clicks donate on the donation page. Make your selections below:
      </Label>
      <br />
      <Label for="donation-amounts">Selection Donation Amounts*</Label>
      <Input
        id="donation-amounts"
        name="donation-amounts"
        type="number"
        min={0}
        value={tempDonationAmount || ''}
        onChange={e => setTempDonationAmount(parseInt(e.target.value))}
        disabled={donationAmounts.length === 10}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            e.preventDefault();
            addDonationAmount();
          }
        }}
      />
      <Row className="input-item-list">
        {donationAmounts.map(amount => (
          <div key={amount} className="input-item" onClick={() => removeItemAmount(amount)}>
            <span className="oath-utility inkBlue" key={amount}>
              ${amount}
            </span>
          </div>
        ))}
      </Row>
      {donationAmounts.length === 10 ? (
        <Label for="donation-amounts" className="error">
          You’ve reached the maximum number of donation amounts.
        </Label>
      ) : null}
    </FormGroup>
  );
};
