import axios from 'axios';
import { Dispatch } from 'redux';
import PAGES_TYPES, { BodyCreateProps, BodyUpdateProps } from './types';
import { ENV_CONFIG } from 'config/environment';
import { showToast } from 'components/Toast';
import API_ROUTES from 'services/apiRoutes';
import { getAccessToken } from 'services/storage';

const headers = {
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
};

export const getPages =
  (client_id: string, category: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(PAGES_TYPES.pagesStart());
    const clientID = client_id === 'None' ? '' : client_id;
    const url = `${ENV_CONFIG().API.BASE_URL}${API_ROUTES.GET_PAGES(clientID, `/${category}`)}`;
    try {
      const response = await axios.get(url, {
        headers: { ...headers, Authorization: `Bearer ${getAccessToken()}` },
      });
      dispatch(PAGES_TYPES.pagesSuccess(response.data, category));
    } catch (error: any) {
      showToast(error.message, 'error', error?.response?.status);
      dispatch(PAGES_TYPES.pagesError(error.message));
    }
  };

export const createPage =
  <T extends 'donate' | 'event'>(body: BodyCreateProps<T>, category: T) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(PAGES_TYPES.pagesStart());
    const url = `${ENV_CONFIG().API.BASE_URL}${API_ROUTES.PAGES(category)}`;
    try {
      const response = await axios.post(url, body, {
        headers: { ...headers, Authorization: `Bearer ${getAccessToken()}` },
      });
      dispatch(PAGES_TYPES.pageCreatedSuccess(response.data));
    } catch (error: any) {
      showToast(error.message, 'error', error?.response?.status);
      dispatch(PAGES_TYPES.pageCreatedError(error.message));
    }
  };

export const updatePage =
  <T extends 'donate' | 'event'>(body: BodyUpdateProps<T>, category: T) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(PAGES_TYPES.pagesStart());

    const url = `${ENV_CONFIG().API.BASE_URL}${API_ROUTES.PAGES(category)}`;
    try {
      const response = await axios.put(url, body, {
        headers: { ...headers, Authorization: `Bearer ${getAccessToken()}` },
      });
      dispatch(PAGES_TYPES.pageCreatedSuccess(response.data));
    } catch (error: any) {
      showToast(error.message, 'error', error?.response?.status);
      dispatch(PAGES_TYPES.pageCreatedError(error.message));
    }
  };

export const getGoogleStorageSignedUrl = async (filename: string): Promise<any> => {
  const url = `${ENV_CONFIG().API.BASE_URL}${API_ROUTES.GOOGLE_STORAGE_URL(filename)}`;

  try {
    const response = await axios.get(url, {
      headers: { ...headers, Authorization: `Bearer ${getAccessToken()}` },
    });
    return Promise.resolve(response);
  } catch (error: any) {
    return Promise.reject(error);
  }
};

export const uploadFile =
  (file: File) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const url = (await getGoogleStorageSignedUrl(file.name)).data;
      await axios.put(url, file, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });
    } catch (error: any) {
      showToast(error.message, 'error', error?.response?.status);
      dispatch(PAGES_TYPES.pagesError(error.message));
    }
  };

export const pageCleanUp =
  () =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(PAGES_TYPES.pageCleanUp());
  };

export const getAllPages =
  () =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(PAGES_TYPES.pagesStart());
    const url = `${ENV_CONFIG().API.BASE_URL}${API_ROUTES.GET_PAGES('', '')}`;
    try {
      const response = await axios.get(url, {
        headers: { ...headers, Authorization: `Bearer ${getAccessToken()}` },
      });
      dispatch(PAGES_TYPES.allPagesSuccess(response.data));
    } catch (error: any) {
      showToast(error.message, 'error', error?.response?.status);
      dispatch(PAGES_TYPES.pagesError(error.message));
    }
  };

export const getPageDetail =
  (query_str: string, category: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(PAGES_TYPES.pagesStart());
    const url = `${ENV_CONFIG().API.BASE_URL}${API_ROUTES.GET_PAGE_DETAIL(query_str, category)}`;
    try {
      const response = await axios.get(url, {
        headers: { ...headers, Authorization: `Bearer ${getAccessToken()}` },
      });
      dispatch(PAGES_TYPES.pageDetailSuccess(response.data));
    } catch (error: any) {
      showToast(error.message, 'error', error?.response?.status);
      dispatch(PAGES_TYPES.pageDetailError(error.message));
    }
  };
